import API from "@/api/api";
import { USER_API } from "@/api/endPoints";

const getUser = () => API.get(`${USER_API.INTERNAL.ME}`);
const getUserByClientId = (
  {
    clientId,
    page,
    perpage,
    search,
    filter,
    sorter,
  },
) => API.get(`${USER_API.EXTERNAL.GET_USERS(clientId)}?roles[]=externalUsers-pharmacies-admin&page=${page}&perpage=${perpage}${search || ""}${filter || ""}${sorter || ""}`);
const getUserExternal = ({
  page,
  perpage,
  search,
  filter,
  sorter,
}) => API.get(
  `${USER_API.EXTERNAL.GET}&roles[]=externalUsers-pharmacies-admin&page=${page}&perpage=${perpage}${search || ""}${filter || ""}${sorter || ""}`,
);
const getUserApotekerByClientId = (
  {
    clientId,
    page,
    perpage,
    search,
    filter,
    sorter,
  },
) => API.get(`${USER_API.EXTERNAL.GET_USERS(clientId)}?roles[]=externalUsers-pharmacies-staff&page=${page}&perpage=${perpage}${search || ""}${filter || ""}${sorter || ""}`);
const getUserApoteker = ({
  page,
  perpage,
  search,
  filter,
  sorter,
}) => API.get(
  `${USER_API.EXTERNAL.GET}&roles[]=externalUsers-pharmacies-staff&page=${page}&perpage=${perpage}${search || ""}${filter || ""}${sorter || ""}`,
);

const getUserByClientIdAll = ({
  clientId,
  page,
  perpage,
  search,
  filter,
  sorter,
}) => API.get(`${USER_API.EXTERNAL.GET_USERS(clientId)}?roles[]=externalUsers-pharmacies-admin&roles[]=externalUsers-pharmacies-staff&page=${page}&perpage=${perpage}${search || ""}${filter || ""}${sorter || ""}`);

export {
  getUser,
  getUserByClientId,
  getUserExternal,
  getUserApotekerByClientId,
  getUserApoteker,
  getUserByClientIdAll,
};
