import axios from "axios";
import { USER_API } from "@/api/endPoints";

const baseDomain = `${process.env.VUE_APP_API_HOST}`;
const baseURL = `${baseDomain}/api/`;

const API = axios.create({
  baseURL,
});

const refreshToken = () => API.post(USER_API.REFRESH, {});

let isAlreadyFetchingAccessToken = false;
let isAlreadyReturnGlobalError = false;
let subscribers = [];

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter((callback) => callback(accessToken));// eslint-disable-line
}

function addSubscriber(callback) {// eslint-disable-line
  subscribers.push(callback);// add request queue
}

API.interceptors.request.use((config) => {
  // Do something before request is sent
  const tmp = config; // reassigment lint
  const token = localStorage.getItem("accessToken");
  if (token) tmp.headers.Authorization = `Bearer ${token}`;
  return tmp;
}, (error) => Promise.reject(error));

API.interceptors.response.use((response) => response,
  (error) => {// eslint-disable-line
    if (!error || !error.response) {
      if (!isAlreadyReturnGlobalError) {
        isAlreadyReturnGlobalError = true;
        setTimeout(() => { isAlreadyReturnGlobalError = false; }, 5000);
      }
    }

    const { config, response: { status } } = error;
    const originalRequest = config;

    if (status === 401 && originalRequest.url !== USER_API.REFRESH && config.method !== "delete" && config.url !== "/v1/users/login") {
      const userData = JSON.parse(String(localStorage.getItem("userData")));
      const rmmbrMe = JSON.parse(String(localStorage.getItem("rememberMe")));
      if (rmmbrMe) {
        const isAutoRefresh = rmmbrMe[userData.id];
        // console.log("check auto refresh", isAutoRefresh, rmmbrMe);
        if (!isAutoRefresh) {
          localStorage.removeItem("accessToken");
          window.location.replace("/login");
        }
      }
      // console.log("try reauth");
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;

        // console.log("reauth processing");
        refreshToken().then((response) => {
          isAlreadyFetchingAccessToken = false;

          const newTokenBearer = response.headers.authorization;
          const newAccessToken = newTokenBearer.split(" ")[1];
          localStorage.setItem("accessToken", newAccessToken);
          axios.defaults.headers.common.Authorization = newTokenBearer;
          originalRequest.headers.Authorization = newTokenBearer;

          // console.log("reauth success");
          onAccessTokenFetched(newAccessToken);
        });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken) => {
          // console.log("try request again");
          localStorage.setItem("accessToken", accessToken);
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    if (status === 401 && originalRequest.url === USER_API.REFRESH) {
      localStorage.removeItem("accessToken");
      window.location.replace("/login");
    }

    return Promise.reject(error);
  });

export default API;
