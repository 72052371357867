import API from "@/api/api";
import { ADMIN_API } from "@/api/endPoints";

const getAdmins = ({
  limit, offset, search, filter, sorter,
}) => API.get(
  `${ADMIN_API.GET}?limit=${limit}&offset=${offset}${search || ""}${filter || ""}${sorter || ""}`,
);
const getAdmin = (id) => API.get(`${ADMIN_API.SHOW(id)}`);

export { getAdmins, getAdmin };
