import moment from "moment";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"];

const fullMonthNames = [
  "Januari",
  "Febuari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Dessember",
];

const getMonthName = (mth) => monthNames[Number(mth) - 1];
const getFullMonthName = (mth) => fullMonthNames[Number(mth) - 1];

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-._])[A-Za-z\d#?!@$%^&*-._]{8,15}$/;

const formatDate = (value, format) => {
  moment.locale("id");
  const newDate = new Date(value);
  return (value) ? moment(newDate).format(format) : "-";
};

const formatNumberToRp = (value) => `Rp${value.toLocaleString("id-ID")}`;

export {
  monthNames,
  fullMonthNames,
  getMonthName,
  getFullMonthName,
  passwordRegex,
  formatDate,
  formatNumberToRp,
};
