import { postLogin, postLoginExternal } from "@/api/login";

const LoginStore = {
  namespaced: true,
  actions: {
    async login(_store, payload) {
      const { data } = await postLogin(payload);
      localStorage.setItem("accessToken", data.access_token);
    },
    async loginExternal(_store, payload) {
      const { data } = await postLoginExternal(payload);
      localStorage.setItem("accessToken", data.access_token);
    },
  },
};

export default LoginStore;
