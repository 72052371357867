<template>
  <a-layout id="components-layout-demo-custom-trigger" style="height: 100vh">
    <sidebar-section />
    <a-layout>
      <header-section />
      <a-layout-content>
        <div :style="layoutStyle">
          <router-view @setRightSidebarVisible="setRightSidebarVisible" />
        </div>
      </a-layout-content>
    </a-layout>
    <template v-if="rightSidebarVisible">
      <right-sidebar-section />
    </template>
  </a-layout>
</template>

<script>
import Vue from "vue";
import SidebarSection from "./SidebarSection.vue";
import HeaderSection from "./HeaderSection.vue";
import RightSidebarSection from "./RightSidebarSection.vue";

export default Vue.extend({
  components: { SidebarSection, HeaderSection, RightSidebarSection },
  data() {
    return {
      rightSidebarVisible: false,
    };
  },
  computed: {
    layoutStyle() {
      return (this.$route.name === "Pharmacy")
        ? {
          padding: "10px",
          margin: "0",
          background: this.backgroundComputed,
          borderRadius: "0",
          height: "100%",
        }
        : {
          padding: this.paddingSizeComputed,
          margin: "24px 16px",
          background: this.backgroundComputed,
          borderRadius: "16px",
        };
    },
    backgroundComputed() {
      return this.$route.name !== "Update Stock" ? "#fff" : "transparent";
    },
    paddingSizeComputed() {
      return this.$route.name !== "Update Stock" ? "24px" : "0";
    },
  },
  methods: {
    setRightSidebarVisible(visible) {
      this.rightSidebarVisible = visible;
    },
  },
});
</script>
