import ApolloClient from "apollo-client";
import { fromPromise, ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { API, refreshToken } from "./apiRefreshToken";

const processingRenewToken = async () => {
  try {
    const response = await refreshToken();
    const newTokenBearer = response.headers.authorization;
    const newAccessToken = newTokenBearer.split(" ")[1];
    API.defaults.headers.common.authorization = newTokenBearer;
    await localStorage.setItem("accessToken", newAccessToken);
    return newTokenBearer;
  } catch (error) {
    /* error refresh token */
    console.log(error);
    // throw Error(false);
    return false;
  }
};

const resetToken = onError(({
  graphQLErrors, networkError, operation, forward,
}) => {
  console.log({
    graphQLErrors,
    networkError,
    operation,
    forward,
  });
  if (graphQLErrors) {
    for (let err = 0; err < graphQLErrors.length; err += 1) {
      switch (graphQLErrors[err].extensions.category) {
        case "authentication":
          return fromPromise(processingRenewToken())
            .filter((value) => value)
            .flatMap((newTokenBearer) => {
              if (window.pusher) {
                window.pusher.pusher.config.auth.headers.authorization = newTokenBearer;
              }
              // retry the request, returning the new observable
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: newTokenBearer,
                },
              });
              return forward(operation);
            });
        default:
          console.log("graphqlError: ", graphQLErrors[err]);
      }
    }
  }
  if (networkError) {
    console.log("network error: ", networkError);
  }
  return false;
});

const httpLink = createUploadLink({ uri: process.env.VUE_APP_GRAPHQL_BASE_URL });

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("accessToken");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    resetToken,
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
});

export default client;
