import { getUserApotekerByClientId, getUserApoteker } from "@/api/user";

const PharmacistStore = {
  namespaced: true,
  state: () => ({
    pharmacists: [],
    pharmacist: {},
  }),
  mutations: {
    SET_PHARMACISTS(state, data) {
      state.pharmacists = data;
    },
    SET_PHARMACIST(state, data) {
      state.pharmacist = data;
    },
  },
  actions: {
    // async fetch({ commit }, page) {
    //   const { data } = await getPharmacists(page);
    //   const userData = data.data.data ? data.data.data : data.data;
    //   commit("SET_PHARMACISTS", userData);
    //   return data;
    // },
    async fetchExternalUser({ commit }, page) {
      try {
        const { data } = await getUserApoteker(page);
        const userData = data.data.data ? data.data.data : data.data;
        commit("SET_PHARMACISTS", userData);
        return data.data.data ? data.data : data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
    async fetchExternalUserByClientId({ commit }, page) {
      try {
        const { data } = await getUserApotekerByClientId(page);
        const userData = data.data.data ? data.data.data : data.data;
        commit("SET_PHARMACISTS", userData);
        return data.data.data ? data.data : data;
      } catch (error) {
        commit("SET_PHARMACISTS", []);
        throw error.response.data.message;
      }
    },
    // async fetchById({ commit }, id) {
    //   const { data } = await getPharmacist(id);
    //   commit("SET_PHARMACIST", data.data);
    // },
  },
  getters: {
    getPharmacists(state) {
      return state.pharmacists;
    },
    getPharmacist(state) {
      return state.pharmacist;
    },
  },
};

export default PharmacistStore;
