import API from "./api";
import { MEDICINE_API } from "./endPoints";

const getMedicines = ({
  limit, offset, search, sorter, filter,
}) => API.get(`${MEDICINE_API.GET}?limit=${limit}&offset=${offset}${search || ""}${filter || ""}${sorter || ""}`);
const getMedicine = (id) => API.get(`${MEDICINE_API.SHOW(id)}`);

export {
  getMedicines,
  getMedicine,
};
