/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
export const helperStatus = [
  {
    type: "hypertension",
    url: "https://devassets.b-cdn.net/status/Icon Darah Tinggi white.png",
  },
  {
    type: "smoker",
    url: "https://devassets.b-cdn.net/status/Icon Merokok white.png",
  },
  {
    type: "diabetes",
    url: "https://devassets.b-cdn.net/status/Icon Diabetes white.png",
  },
  {
    type: "alcohol",
    url: "https://devassets.b-cdn.net/status/Icon Alcohol white.png",
  },
  {
    type: "heart_problems",
    url: "https://devassets.b-cdn.net/status/Icon Gangguan Jantung white.png",
  },
  {
    type: "menopause",
    url: "https://devassets.b-cdn.net/status/Icon Menopause white.png",
  },
  {
    type: "cancer",
    url: "https://devassets.b-cdn.net/status/Icon Kanker white.png",
  },
  {
    type: "pregnant",
    url: "https://devassets.b-cdn.net/status/Icon Hamil white.png",
  },
  {
    type: "kidney_failure",
    url: "https://devassets.b-cdn.net/status/Icon Gagal Ginjal white.png",
  },
];

export const helperIcon = {
  asset10: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 10.png",
  asset11: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 11.png",
  asset12: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 12.png",
  asset13: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 13.png",
  asset14: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 14.png",
  asset15: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 15.png",
  asset16: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 16.png",
  asset17: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 17.png",
  asset18: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 18.png",
  asset19: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 19.png",
  asset2: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 2.png",
  asset20: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 20.png",
  asset21: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 21.png",
  asset22: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 22.png",
  asset23: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 23.png",
  asset24: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 24.png",
  asset25: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 25.png",
  asset26: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 26.png",
  asset27: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 27.png",
  asset28: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 28.png",
  asset29: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 29.png",
  asset3: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 3.png",
  asset30: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 30.png",
  asset31: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 31.png",
  asset32: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 32.png",
  asset33: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 33.png",
  asset34: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 34.png",
  asset35: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 35.png",
  asset4: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 4.png",
  asset5: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 5.png",
  asset6: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 6.png",
  asset7: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 7.png",
  asset8: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 8.png",
  asset9: "https://devassets.b-cdn.net/pharmacy/Icon/Asset 9.png",
};

export const dateConvert = (val) => {
  if (!val) return "-";
  const date = new Date(val);
  // 01, 02, 03, ... 29, 30, 31
  const dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
  // 01, 02, 03, ... 10, 11, 12
  const MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
  // 1970, 1971, ... 2015, 2016, ...
  const yyyy = date.getFullYear();
  return `${dd}/${MM}/${yyyy}`;
};
