import Login from "@/views/user/Login.vue";
import NewPassword from "@/views/user/NewPassword.vue";
import SignUpPassword from "@/views/user/SignUpPassword.vue";
import ForgotPassword from "@/views/user/ForgotPassword.vue";

import PasswordChanged from "@/views/user/message/PasswordChanged.vue";
import PasswordRecoverySent from "@/views/user/message/PasswordRecoverySent.vue";
import NotVerified from "@/views/user/message/NotVerified.vue";
import VerificationSent from "@/views/user/message/VerificationSent.vue";

export default [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Masuk",
      plainLayout: true,
    },
  },
  {
    path: "/sign-up-password/:token/:user",
    name: "SignUpPassword",
    component: SignUpPassword,
    meta: {
      title: "Buat Password Baru",
      plainLayout: true,
    },
  },
  {
    path: "/new-password/:token/:user",
    name: "NewPassword",
    component: NewPassword,
    meta: {
      title: "Password Baru",
      plainLayout: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Lupa Password",
      plainLayout: true,
    },
  },
  {
    path: "/password-recovery-sent",
    name: "PasswordRecoverySent",
    component: PasswordRecoverySent,
    meta: {
      title: "Pemulihan Password Dikirim",
      plainLayout: true,
    },
  },
  {
    path: "/not-verified",
    name: "NotVerified",
    component: NotVerified,
    meta: {
      title: "Belum Terverifikasi",
      plainLayout: true,
    },
  },
  {
    path: "/verification-sent",
    name: "VerificationSent",
    component: VerificationSent,
    meta: {
      title: "Email Verifikasi Dikirim",
      plainLayout: true,
    },
  },
  {
    path: "/password-changed/:status",
    name: "PasswordChanged",
    component: PasswordChanged,
    meta: {
      title: "Password Diubah",
      plainLayout: true,
    },
  },
];
