import gql from "graphql-tag";
import graphqlClient from "@/apollo/graphql";

const HistoryPrescriptionsStore = {
  namespaced: true,
  state: () => ({
    uiFlags: {
      isFetching: false,
      isUpdating: false,
    },
    historyPrescriptions: [],
    paginatorInfo: {
      limit: 5,
      offset: 0,
      total: 2,
    },
  }),
  mutations: {
    SET_HISTORY_PRESCRIPTION(state, data) {
      state.historyPrescriptions = data;
    },
    SET_HISTORY_PRESCRIPTION_MORE(state, data) {
      state.historyPrescriptions = state.historyPrescriptions.concat(data);
    },
    SET_HISTORY_PRESCRIPTION_PAGINATOR(state, data) {
      state.paginatorInfo = {
        limit: data.limit,
        offset: data.offset,
        total: data.total,
      };
    },
    SET_COUNT_HISTORY_PRESCRIPTION(state, data) {
      state.countOrders = data;
    },
    SET_SELECTED_ID(state, data) {
      state.selectedId = data;
    },
    TOGGLE_QUERY_STATUS(state, status) {
      state.uiFlags.isFetching = status;
    },
    TOGGLE_MUTATE_STATUS(state, status) {
      state.uiFlags.isUpdating = status;
    },
  },
  actions: {
    async fetchHistoryPrescriptions({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query historyPrescriptions(
              $patient_id: Uuid,
              $limit: Int,
              $offset: Int,
            ) {
              historyPrescriptions(patient_id: $patient_id, limit: $limit, offset: $offset){
                data{
                  id
                  prescription_number
                  updated_at
                  doctor_name
                  diagnosis
                }
                paginatorInfo{
                  total
                  limit
                  offset
                }
              }
            }
          `,
          variables: {
            patient_id: payload.patientId,
            limit: payload.limit,
            offset: payload.offset,
          },
          fetchPolicy: "network-only",
        });
        if (payload.offset > 0) {
          commit("SET_HISTORY_PRESCRIPTION_MORE", data.data.historyPrescriptions.data);
        } else {
          commit("SET_HISTORY_PRESCRIPTION", data.data.historyPrescriptions.data);
        }
        commit("SET_HISTORY_PRESCRIPTION_PAGINATOR", data.data.historyPrescriptions.paginatorInfo);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.historyPrescriptions;
      } catch (error) {
        commit("SET_HISTORY_PRESCRIPTION", []);
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
  },
  getters: {
    getHistoryPrescriptions(state) {
      return state.historyPrescriptions;
    },
    getPaginatorInfo(state) {
      return state.paginatorInfo;
    },
  },
};

export default HistoryPrescriptionsStore;
