import {
  getAdmin,
  postUser,
  patchUser,
  removeUser,
  postResendEmail,
  getExternalUserVerify,
  patchPasswordExternal,
  postForgetPassword,
  getForgetPasswordVerify,
} from "@/api/user-external";

const UserExternalStore = {
  namespaced: true,
  state: () => ({
    admin: {},
    chatHistory: [],
    appointmentBookingHistory: [],
  }),
  mutations: {
    SET_ADMIN(state, data) {
      state.admin = data;
    },
  },
  actions: {
    async createUser(_store, payload) {
      try {
        await postUser(payload);
      } catch (error) {
        throw error.response.data.message;
      }
    },
    async updateUser(_store, payload) {
      try {
        await patchUser(payload);
      } catch (error) {
        throw error.response.data.message;
      }
    },
    async removeUser(_store, payload) {
      try {
        await removeUser(payload);
      } catch (error) {
        throw error.response.data.message;
      }
    },
    async resendEmail(_store, payload) {
      try {
        await postResendEmail(payload.id, payload.data);
      } catch (error) {
        throw error.response.data.message;
      }
    },
    async fetchById({ commit }, payload) {
      try {
        const { data } = await getAdmin(payload);
        commit("SET_ADMIN", data.data);
      } catch (error) {
        throw error.response.data.message;
      }
    },
    async verifyUser(_store, token) {
      try {
        const { data } = await getExternalUserVerify(token);
        return data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
    async updatePassword(_store, payload) {
      try {
        await patchPasswordExternal(payload);
      } catch (error) {
        throw error.response.data.message;
      }
    },
    async sendForgetPassword(_store, payload) {
      try {
        const { data } = await postForgetPassword(payload);
        return data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
    async verifyForgetPassword(_store, token) {
      try {
        const { data } = await getForgetPasswordVerify(token);
        return data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
  },
  getters: {
    getAdmin(state) {
      return state.admin;
    },
  },
};

export default UserExternalStore;
