import { getAll } from "@/api/city";

const CityStore = {
  namespaced: true,
  state: () => ({
    cities: [],
  }),
  mutations: {
    SET_CITIES(state, data) {
      state.cities = data;
    },
  },
  actions: {
    async fetchCities({ commit }, page) {
      try {
        const { data } = await getAll(page);
        commit("SET_CITIES", data.data);
        return data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
  },
  getters: {
    getCities(state) {
      return state.cities;
    },
  },
};

export default CityStore;
