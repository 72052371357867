import gql from "graphql-tag";
import graphqlClient from "@/apollo/graphql";

const ShippingMethodStore = {
  namespaced: true,
  state: () => ({
    uiFlags: {
      isFetching: false,
      isUpdating: false,
    },
    shippingMethods: [],
    paginatorInfo: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      count: 0,
    },
  }),
  mutations: {
    SET_SHIPPING_METHODS(state, data) {
      state.shippingMethods = data;
    },
    SET_SHIPPING_METHODS_PAGINATOR(state, data) {
      state.paginatorInfo = {
        total: data.total,
        perPage: data.perPage,
        currentPage: data.currentPage,
        count: data.count,
      };
    },
    TOGGLE_QUERY_STATUS(state, status) {
      state.uiFlags.isFetching = status;
    },
    TOGGLE_MUTATE_STATUS(state, status) {
      state.uiFlags.isUpdating = status;
    },
  },
  actions: {
    async fetchOrders({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query orders(
              $page: Uuid,
              $first: OrderState,
            ) {
              shippingMethods(store_id: $store_id, state: $state, relative_date: $relative_date){
                paginatorInfo {
                  total
                }
                data{
                  id
                  name
                }
              }
            }
          `,
          variables: {
            page: payload.page,
            first: payload.first,
          },
          fetchPolicy: "network-only",
        });
        commit("SET_SHIPPING_METHODS", data.data.shippingMethods.data);
        commit("SET_SHIPPING_METHODS_PAGINATOR", data.data.orders.paginatorInfo);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.shippingMethods;
      } catch (error) {
        commit("SET_SHIPPING_METHODS", []);
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
  },
  getters: {
    getShippingMethods(state) {
      return state.shippingMethods;
    },
  },
};

export default ShippingMethodStore;
