import gql from "graphql-tag";

const GET_PRODUCTS = gql`
  query getProducts {
    products (first: 10 page: 1) {
      paginatorInfo {
        count
        currentPage
        perPage
        total
      }
      data {
        id
        name
        description
        client_id
        variants {
          id
          product_id
          name
          medicine_reference_id
          medicine_reference_name
          discontinue_on
          sku
          attributes {
            id
            name
            pivot {
              value
            }
          }
        }
      }
    }
  }
`;

const GET_VARAINTS = gql`
  query getVariants {
    variants (first: 10 page: 1) {
      paginatorInfo {
        count
        currentPage
        perPage
        total
      }
      data {
        id
        product_id
        name
        sku
        description
        medicine_reference_id
        medicine_reference_name
        product {
          name
          description
          client_id
        }
        attributes {
          id
          name
          pivot {
            value
          }
        }
      }
    }
  }
`;

const GET_ATTRIBUTES = gql`
  query getAttributes {
    attributes (first: 10 page: 1) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        name
        validation
        type
      }
    }
  }
`;

export {
  GET_PRODUCTS,
  GET_VARAINTS,
  GET_ATTRIBUTES,
};
