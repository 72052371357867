import gql from "graphql-tag";
import graphqlClient from "@/apollo/graphql";
import { GET_ATTRIBUTES } from "@/apollo/products";

const ProductStore = {
  namespaced: true,
  state: () => ({
    uiFlags: {
      isFetching: false,
      isUpdating: false,
    },
    products: [],
    product: [],
    variants: [],
    variant: [],
    attributes: [],
    productsPaginatorInfo: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      count: 0,
    },
    variantsPaginatorInfo: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      count: 0,
    },
  }),
  mutations: {
    SET_PRODUCTS(state, data) {
      state.products = data;
    },
    SET_PRODUCTS_PAGINATOR(state, data) {
      state.productsPaginatorInfo = {
        total: data.total,
        perPage: data.perPage,
        currentPage: data.currentPage,
        count: data.count,
      };
    },
    SET_VARIANTS(state, data) {
      state.variants = data;
    },
    SET_VARIANTS_PAGINATOR(state, data) {
      state.variantsPaginatorInfo = {
        total: data.total,
        perPage: data.perPage,
        currentPage: data.currentPage,
        count: data.count,
      };
    },
    SET_ATTRIBUTES(state, data) {
      state.attributes = data;
    },
    TOGGLE_QUERY_STATUS(state, status) {
      state.uiFlags.isFetching = status;
    },
    TOGGLE_MUTATE_STATUS(state, status) {
      state.uiFlags.isUpdating = status;
    },
  },
  actions: {
    async fetchProduct({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchProduct($id: Uuid!) {
              product (id: $id) {
                id
                name
                description
                client_id
                variants {
                  id
                  name
                  sku
                  description
                  medicine_reference_id
                  medicine_reference_name
                  attributes {
                    id
                    name
                    pivot {
                      value
                    }
                  }
                  photos {
                    id
                    variant_id
                    variant_photo
                  }
                }
              }
              attributes (first: 10 page: 1) {
                data {
                  id
                  name
                  validation
                  type
                }
              }
            }
          `,
          variables: {
            id: options.productId,
          },
          // fetchPolicy: "network-only",
          fetchPolicy: "no-cache",
        });
        // commit("SET_PRODUCTS", data.data.products.data);
        // commit("SET_PRODUCTS_PAGINATOR", data.data.products.paginatorInfo);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
    async fetchProductsAndProductByName({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchProductsAndProductByName($searchKeyword: String $first: Int $page: Int $name: String!) {
              products (searchKeyword: $searchKeyword first: $first page: $page) {
                paginatorInfo {
                  total
                }
                data {
                  id
                  name
                }
              }
              productByName (name: $name) {
                id
                name
              }
            }
          `,
          variables: {
            searchKeyword: options.productName ? options.productName : "",
            first: options.first,
            page: options.page,
            name: options.productName ? options.productName : "",
          },
          // fetchPolicy: "network-only",
          fetchPolicy: "no-cache",
        });
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
    async fetchVariants({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchVariants($searchKeyword: String $first: Int $page: Int) {
              variants (searchKeyword: $searchKeyword first: $first page: $page) {
                paginatorInfo {
                  count
                  currentPage
                  perPage
                  total
                }
                data {
                  id
                  product_id
                  name
                  sku
                  description
                  medicine_reference_id
                  medicine_reference_name
                  product {
                    name
                    description
                    client_id
                  }
                  attributes {
                    id
                    name
                    pivot {
                      value
                    }
                  }
                }
              }
            }
          `,
          variables: {
            searchKeyword: options.variantName ? options.variantName : "",
            first: options.first,
            page: options.page,
          },
          // fetchPolicy: "network-only",
          fetchPolicy: "no-cache",
        });
        commit("SET_VARIANTS", data.data.variants.data);
        commit("SET_VARIANTS_PAGINATOR", data.data.variants.paginatorInfo);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.variants;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
    async fetchVariantsByOrder({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchVariantsByOrder(
              $orderBy: [QueryVariantsOrderByClause!]
              $searchKeyword: String
              $first: Int
              $page: Int
            ) {
              variants (orderBy: $orderBy searchKeyword: $searchKeyword first: $first page: $page) {
                paginatorInfo {
                  count
                  currentPage
                  perPage
                  total
                }
                data {
                  id
                  product_id
                  name
                  sku
                  description
                  medicine_reference_id
                  medicine_reference_name
                  product {
                    name
                    description
                    client_id
                  }
                  attributes {
                    id
                    name
                    pivot {
                      value
                    }
                  }
                }
              }
            }
          `,
          variables: {
            orderBy: options.orderBy,
            searchKeyword: options.variantName,
            first: options.first,
            page: options.page,
          },
          // fetchPolicy: "network-only",
          fetchPolicy: "no-cache",
        });
        commit("SET_VARIANTS", data.data.variants.data);
        commit("SET_VARIANTS_PAGINATOR", data.data.variants.paginatorInfo);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.variants;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
    async fetchVariant({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchVariant($id: Uuid!) {
              variant (id: $id) {
                id
                product_id
                name
                sku
                description
                medicine_reference_id
                medicine_reference_name
                product {
                  name
                  description
                  client_id
                }
                attributes {
                  id
                  name
                  pivot {
                    value
                  }
                }
                photos {
                  id
                  variant_id
                  variant_photo
                }
              }
              attributes (first: 10 page: 1) {
                data {
                  id
                  name
                  validation
                  type
                }
              }
            }
          `,
          variables: {
            id: options.id,
          },
          // fetchPolicy: "network-only",
          fetchPolicy: "no-cache",
        });
        // commit("SET_VARIANTS", data.data.variants.data);
        // commit("SET_VARIANTS_PAGINATOR", data.data.variants.paginatorInfo);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
    async fetchAtributes({ commit }) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: GET_ATTRIBUTES,
          // fetchPolicy: "network-only",
          fetchPolicy: "no-cache",
        });
        commit("SET_ATTRIBUTES", data.data.attributes.data);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.attributes;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
    async createProduct({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation createProduct($input: ProductInput!) {
              createProduct(input: $input) {
                id
                name
              }
            }
          `,
          variables: { input: payload },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async createVariant({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation createVariant($input: CreateVariantInput!) {
              createVariant(input: $input) {
                id
              }
            }
          `,
          variables: { input: payload },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async saveAttributesToVariant({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation saveAttributesToVariant($variant_id:  Uuid! $attributes: [saveAttributesToVariantInput!]!) {
              saveAttributesToVariant(variant_id: $variant_id attributes: $attributes) {
                id
                variant_id
                attribute_id
                value
              }
            }
          `,
          variables: {
            variant_id: payload.variant_id,
            attributes: payload.attributes,
          },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async updateProduct({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation updateProduct($id:  Uuid! $input: ProductInput!) {
              updateProduct(id: $id input: $input) {
                id
                name
                client_id
              }
            }
          `,
          variables: {
            id: payload.id,
            input: payload.input,
          },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async updateVariant({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation updateVariant($id:  Uuid! $input: UpdateVariantInput!) {
              updateVariant(id: $id input: $input) {
                id
                product_id
                name
                sku
                description
                medicine_reference_id
                medicine_reference_name
              }
            }
          `,
          variables: {
            id: payload.id,
            input: payload.input,
          },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async updateProductVariantAttribute({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation updateProductVariantAttribute(
              $product_id:  Uuid!
              $input_product: ProductInput!
              $variant_id:  Uuid!
              $input_varaint: UpdateVariantInput!
              $attr_variant_id:  Uuid!
              $attributes: [saveAttributesToVariantInput!]!
            ) {
              updateProduct(id: $product_id input: $input_product) {
                id
                name
                client_id
              }
              updateVariant(id: $variant_id input: $input_varaint) {
                id
                product_id
                name
                sku
                description
                medicine_reference_id
                medicine_reference_name
              }
              saveAttributesToVariant(variant_id: $attr_variant_id attributes: $attributes) {
                id
                variant_id
                attribute_id
                value
              }
            }
          `,
          variables: {
            product_id: payload.product_id,
            input_product: payload.input_product,
            variant_id: payload.variant_id,
            input_varaint: payload.input_varaint,
            attr_variant_id: payload.attr_variant_id,
            attributes: payload.attributes,
          },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data;
      } catch (error) {
        console.log("error: ", error);
        throw error.response.data;
      }
    },
    async updateProductVariantAttributePhoto({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation updateProductVariantAttributePhoto(
              $product_id:  Uuid!
              $input_product: ProductInput!
              $variant_id:  Uuid!
              $input_varaint: UpdateVariantInput!
              $attr_variant_id:  Uuid!
              $attributes: [saveAttributesToVariantInput!]!
              $photo_variant_id: Uuid!
              $files: [Upload!]!
            ) {
              updateProduct(id: $product_id input: $input_product) {
                id
                name
                client_id
              }
              updateVariant(id: $variant_id input: $input_varaint) {
                id
                product_id
                name
                sku
                description
                medicine_reference_id
                medicine_reference_name
              }
              saveAttributesToVariant(variant_id: $attr_variant_id attributes: $attributes) {
                id
                variant_id
                attribute_id
                value
              }
              saveVariantPhotos(variant_id: $photo_variant_id files: $files) {
                photos {
                  id
                  variant_id
                  variant_photo
                }
                errors {
                  photo
                  error_message
                }
              }
            }
          `,
          variables: {
            product_id: payload.product_id,
            input_product: payload.input_product,
            variant_id: payload.variant_id,
            input_varaint: payload.input_varaint,
            attr_variant_id: payload.attr_variant_id,
            attributes: payload.attributes,
            photo_variant_id: payload.photo_variant_id,
            files: payload.photos,
          },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data;
      } catch (error) {
        console.log("error: ", error);
        throw error.response.data;
      }
    },
    async updateProductVariantAttributePhotoWithDelete({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation updateProductVariantAttributePhotoWithDelete(
              $product_id:  Uuid!
              $input_product: ProductInput!
              $variant_id:  Uuid!
              $input_varaint: UpdateVariantInput!
              $attr_variant_id:  Uuid!
              $attributes: [saveAttributesToVariantInput!]!
              $photo_id: Uuid!
              $photo_variant_id: Uuid!
              $files: [Upload!]!
            ) {
              updateProduct(id: $product_id input: $input_product) {
                id
                name
                client_id
              }
              updateVariant(id: $variant_id input: $input_varaint) {
                id
                product_id
                name
                sku
                description
                medicine_reference_id
                medicine_reference_name
              }
              saveAttributesToVariant(variant_id: $attr_variant_id attributes: $attributes) {
                id
                variant_id
                attribute_id
                value
              }
              deleteVariantPhoto(id: $photo_id) {
                id
                variant_id
                variant_photo
              }
              saveVariantPhotos(variant_id: $photo_variant_id files: $files) {
                photos {
                  id
                  variant_id
                  variant_photo
                }
                errors {
                  photo
                  error_message
                }
              }
            }
          `,
          variables: {
            product_id: payload.product_id,
            input_product: payload.input_product,
            variant_id: payload.variant_id,
            input_varaint: payload.input_varaint,
            attr_variant_id: payload.attr_variant_id,
            attributes: payload.attributes,
            photo_id: payload.photo_id,
            photo_variant_id: payload.photo_variant_id,
            files: payload.photos,
          },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data;
      } catch (error) {
        console.log("error: ", error);
        throw error.response.data;
      }
    },
    async deleteAttributesFromVariant({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation deleteAttributesFromVariant($variant_id:  Uuid! $attribute_ids: [Uuid!]!) {
              deleteAttributesFromVariant(variant_id: $variant_id attribute_ids: $attribute_ids) {
                id
                variant_id
                value
                deleted_at
              }
            }
          `,
          variables: {
            variant_id: payload.variantId,
            attribute_ids: payload.attributesId,
          },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async deleteVariant({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation deleteVariant($id:  Uuid!) {
              deleteVariant(id: $id) {
                id
                name
                deleted_at
              }
            }
          `,
          variables: {
            id: payload.variantId,
          },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async deleteProducts({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation deleteProducts($ids: [Uuid!]!) {
              deleteProducts(ids: $ids) {
                id
                name
              }
            }
          `,
          variables: { ids: payload },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async saveVariantPhotos({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation saveVariantPhotos($variant_id: Uuid! $files: [Upload!]!) {
              saveVariantPhotos(variant_id: $variant_id files: $files) {
                photos {
                  id
                  variant_id
                  variant_photo
                }
                errors {
                  photo
                  error_message
                }
              }
            }
          `,
          variables: {
            variant_id: payload.variant_id,
            files: payload.photos,
          },
        });
        commit("TOGGLE_MUTATE_STATUS", true);
        return data;
      } catch (error) {
        throw error.response;
      }
    },
  },
  getters: {
    getProducts(state) {
      return state.products;
    },
    getVariants(state) {
      return state.variants;
    },
    getAtributes(state) {
      return state.attributes;
    },
    getUIFlags(state) {
      return state.uiFlags;
    },
    getProductsPaginatorInfo(state) {
      return state.productsPaginatorInfo;
    },
    getVariantsPaginatorInfo(state) {
      return state.variantsPaginatorInfo;
    },
  },
};

export default ProductStore;
