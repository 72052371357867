<template>
  <div>
    <left-content-page v-if="isShowPage" :title="title" :subTitle="subTitle">
      <a-button type="primary" size="large" shape="round"
      :loading="loading" @click="handleSubmit" class="margin-baseT">
        Kirim Ulang Email Verifikasi
      </a-button>
    </left-content-page>
  </div>
</template>

<script>
import Vue from "vue";
import { Button } from "ant-design-vue";
import LeftContentPage from "@/components/pages/LeftContentPage.vue";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "NotVerified",
  data() {
    return {
      title: "Akun Anda belum terverifikasi",
      subTitle: "Periksa kembali email Anda dan ikuti petunjuk yang diberikan untuk melanjutkan proses verifikasi akun Anda.",
      loading: false,
      isShowPage: false,
      isSubmitError: false,
    };
  },
  components: {
    "a-button": Button,
    LeftContentPage,
  },
  methods: {
    ...mapActions({
      resendEmailInternal: "UserStore/resendEmail",
      resendEmailExternal: "UserExternalStore/resendEmail",
    }),
    handleSubmit() {
      this.loading = true;
      const email = String(localStorage.getItem("email"));
      if (email.includes("prixa.ai")) {
        this.resendEmailInternal({ email })
          .then(() => {
            this.loading = false;
            this.$router.push({ path: "verification-sent" });
          })
          .catch(() => {
            this.loading = false;
            this.isSubmitError = true;
          });
      } else {
        this.resendEmailExternal({ email })
          .then(() => {
            this.loading = false;
            this.$router.push({ path: "verification-sent" });
          })
          .catch(() => {
            this.loading = false;
            this.isSubmitError = true;
          });
      }
    },
  },
  created() {
    this.isShowPage = true;
  },
});
</script>
