<template>
  <left-content-page v-if="isShowPage" :title="title" :subTitle="subTitle"/>
</template>

<script>
import Vue from "vue";
import LeftContentPage from "@/components/pages/LeftContentPage.vue";

export default Vue.extend({
  name: "PasswordRecoverySent",
  data() {
    return {
      title: "Petunjuk pemulihan password telah dikirim",
      email: "",
      isShowPage: false,
    };
  },
  computed: {
    subTitle() {
      const userEmail = this.email !== "" ? this.email : "Anda";
      return `Silahkan periksa email ${userEmail} dan ikuti petunjuk yang diberikan untuk memulihkan password Anda.`;
    },
  },
  components: {
    LeftContentPage,
  },
  mounted() {
    const userEmail = String(localStorage.getItem("email"));
    this.email = userEmail;
    localStorage.removeItem("email");
  },
  created() {
    this.isShowPage = true;
  },
});
</script>
