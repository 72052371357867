import gql from "graphql-tag";
import graphqlClient from "@/apollo/graphql";

const OrderDeliveryStore = {
  namespaced: true,
  state: () => ({
    uiFlags: {
      isFetching: false,
      isUpdating: false,
    },
    order: {},
  }),
  mutations: {
    SET_ORDER(state, data) {
      state.order = data;
    },
    SET_UPDATED_ORDER_HISTORY(state, data) {
      const statePaxcel = {
        ORDER_IS_WAITING_FOR_PICKED_UP: "RTP",
        RE_ATTEMPT_PICKUP: "RAP",
        COURIER_ARRIVED_AT_PICKUP_LOCATION: "COL",
        ORDER_IS_PICKED_BY_COURIER: "PAPV",
        ORDER_CANCELED_BY_COURIER: "PRJL",
        ITEM_ON_THE_WAY_TO_CUSTOMER: "POD",
        COURIER_ARRIVED_AT_DESTINATION: "COD",
        ITEM_DELIVERED: "PDO",
        ITEM_UNDELIVERED: "UNDLM",
        ITEM_RETURNED: "RTN",
      };

      state.order.delivery.state = statePaxcel[data.state];
      state.order.history = state.order.history.concat(data);
    },
    TOGGLE_QUERY_STATUS(state, status) {
      state.uiFlags.isFetching = status;
    },
    TOGGLE_MUTATE_STATUS(state, status) {
      state.uiFlags.isUpdating = status;
    },
  },
  actions: {
    async fetchOrder({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query order($id: Uuid!) {
              order(id: $id){
                id
                delivery{
                  tracking
                  number
                  state
                  provider_name
                  driver_name
                }
                history{
                  state
                  updated_at
                }
                state
                updated_at
              }
            }
          `,
          variables: {
            id: payload.id,
          },
          fetchPolicy: "network-only",
        });
        commit("SET_ORDER", data.data.order);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.order;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async updatedOrderHistory({ commit }, payload) {
      commit("SET_UPDATED_ORDER_HISTORY", payload);
    },
    async subscribeOrderHistory(_vuex, payload) {
      try {
        const data = await graphqlClient.defaultClient.subscribe({
          query: gql`
            subscription orderHistory($store_id: Uuid!, $order_id: Uuid!) {
              orderHistory(store_id: $store_id, order_id: $order_id) {
                state
                updated_at
              }
            }
          `,
          variables: {
            ...payload,
          },
        });
        return data;
      } catch (error) {
        return error;
      }
    },
  },
  getters: {
    getOrder(state) {
      return state.order;
    },
  },
};

export default OrderDeliveryStore;
