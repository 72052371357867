import { getAdmins, getAdmin } from "@/api/admin";
import { getUserByClientId, getUserExternal } from "@/api/user";

const AdminStore = {
  namespaced: true,
  state: () => ({
    admins: [],
    admin: {},
  }),
  mutations: {
    SET_ADMINS(state, data) {
      state.admins = data;
    },
    SET_ADMIN(state, data) {
      state.admin = data;
    },
  },
  actions: {
    async fetch({ commit }, page) {
      const { data } = await getAdmins(page);
      const userData = data.data.data ? data.data.data : data.data;
      commit("SET_ADMINS", userData);
      return data;
    },
    async fetchExternalUser({ commit }, page) {
      try {
        const { data } = await getUserExternal(page);
        const userData = data.data.data ? data.data.data : data.data;
        commit("SET_ADMINS", userData);
        return data.data.data ? data.data : data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
    async fetchExternalUserByClientId({ commit }, page) {
      try {
        const { data } = await getUserByClientId(page);
        const userData = data.data.data ? data.data.data : data.data;
        commit("SET_ADMINS", userData);
        return data.data.data ? data.data : data;
      } catch (error) {
        commit("SET_ADMINS", []);
        throw error.response.data.message;
      }
    },
    async fetchById({ commit }, id) {
      const { data } = await getAdmin(id);
      commit("SET_ADMIN", data.data);
    },
  },
  getters: {
    getAdmins(state) {
      return state.admins;
    },
    getAdmin(state) {
      return state.admin;
    },
  },
};

export default AdminStore;
