<template>
  <div>
    <left-content-page v-if="isShowPage" :smallTitle="smallTitle" :title="title">
      <password-form
      :email="email"
      :buttonName="buttonName"
      @submitPassword="submitPassword"
      :token="token"/>
    </left-content-page>
    <div class="loading-animation" v-else>
      <hollow-dots-spinner
        :animation-duration="1000"
        :dot-size="15"
        :dots-num="3"
        color="#0046AB"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
/* import {
  message,
} from "ant-design-vue"; */
import LeftContentPage from "@/components/pages/LeftContentPage.vue";
import PasswordForm from "@/components/forms/PasswordForm.vue";
import { mapActions } from "vuex";
import { HollowDotsSpinner } from "epic-spinners";

export default Vue.extend({
  name: "NewPasword",
  data() {
    return {
      smallTitle: "UBAH PASSOWRD",
      title: "Masukkan password baru untuk akun Anda:",
      buttonName: "Ubah Password",
      isShowPage: false,
      email: "",
      token: "",
    };
  },
  components: {
    LeftContentPage,
    PasswordForm,
    HollowDotsSpinner,
  },
  methods: {
    ...mapActions("UserExternalStore", {
      verifyExternalForgetPassword: "verifyForgetPassword",
      updateExternalUserPassword: "updatePassword",
    }),
    submitPassword(data) {
      const { updatePasswordData, setLoading } = data;
      const { user } = this.$route.params;
      if (user === "external") {
        this.updateExternalUserPassword(updatePasswordData).then(() => {
          setLoading(false);
          this.$router.push({ name: "PasswordChanged", params: { status: true } });
        }).catch(() => {
          setLoading(false);
          this.$router.push({ name: "PasswordChanged", params: { status: false } });
        });
      }
    },
  },
  created() {
    const { user, token } = this.$route.params;
    this.token = token;
    if (user === "external") {
      this.verifyExternalForgetPassword(token).then((response) => {
        this.email = response.data;
        this.isShowPage = true;
      }).catch(() => {
        this.isShowPage = false;
        window.location.href = "/login";
      });
    }
  },
});
</script>

<style lang="scss" scoped>
.loading-animation {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
