var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Email","validate-status":_vm.checkError('email') ? 'error' : '',"help":_vm.checkError('email') || ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          { rules: [{ required: true, message: 'Data tidak valid',
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }] } ]),expression:"[\n          'email',\n          { rules: [{ required: true, message: 'Data tidak valid',\n          pattern: /^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$/ }] },\n        ]"}],attrs:{"type":"email","size":"large","placeholder":"nama@email.com"}})],1),_c('a-form-item',{attrs:{"label":"Password","validate-status":_vm.checkError('password') ? 'error' : '',"help":_vm.checkError('password') || ''}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [{
              required: true,
              message: 'Data tidak valid',
              pattern: _vm.passwordRegex,
            }]
          } ]),expression:"[\n          'password',\n          {\n            rules: [{\n              required: true,\n              message: 'Data tidak valid',\n              pattern: passwordRegex,\n            }]\n          },\n        ]"}],attrs:{"size":"large","placeholder":"Tulis password"}})],1),_c('router-link',{staticClass:"p-small-link color-secondary",attrs:{"to":"/forgot-password"}},[_vm._v(" Lupa password? ")]),_c('a-form-item',[_c('a-button',{staticClass:"margin-largeT login-button",attrs:{"block":"","type":"primary","size":"large","shape":"round","html-type":"submit","disabled":_vm.hasErrors(_vm.form.getFieldsError()),"loading":_vm.loading}},[_vm._v(" Masuk ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }