<template>
  <left-content-page :smallTitle="smallTitle" :title="title">
    <forgot-password-form/>
  </left-content-page>
</template>

<script>
import Vue from "vue";
import LeftContentPage from "@/components/pages/LeftContentPage.vue";
import ForgotPasswordForm from "@/components/forms/ForgotPasswordForm.vue";

export default Vue.extend({
  name: "ForgotPasword",
  data() {
    return {
      smallTitle: "LUPA PASSOWRD",
      title: "Ke mana petunjuk pemulihan password perlu dikirim?",
      buttonName: "GantiPassword",
    };
  },
  components: {
    LeftContentPage,
    ForgotPasswordForm,
  },
});
</script>
