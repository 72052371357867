const UserStore = {
  namespaced: true,
  state: () => ({
    roles: [],
  }),
  getters: {
    getRoles(state) {
      return state.roles;
    },
  },
};

export default UserStore;
