import { getCompanies } from "@/api/company";

const getDefaultState = () => (
  {
    id: "",
    client_name: "",
    client_pic: "",
    client_phone: "",
    client_email: "",
    client_legal_name: "",
    client_address: "",
    deleted_at: "",
    created_at: "",
    updated_at: "",
    client_logo: "",
    industry: {},
  }
);

const CompanyStore = {
  namespaced: true,
  state: () => ({
    companies: [],
    companiesByBaymaxSupplier: [],
    companiesByDexterSupplier: [],
    company: getDefaultState(),
  }),
  mutations: {
    SET_COMPANIES(state, data) {
      state.companies = data;
    },
  },
  actions: {
    async fetch({ commit }, page) {
      try {
        const { data } = await getCompanies(page);
        commit("SET_COMPANIES", data.data);
        return data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
  },
  getters: {
    getCompanies(state) {
      return state.companies;
    },
  },
};

export default CompanyStore;
