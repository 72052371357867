import gql from "graphql-tag";
import graphqlClient from "@/apollo/graphql";

const ManageStoreStore = {
  namespaced: true,
  state: () => ({
    uiFlags: {
      isFetching: false,
      isUpdating: false,
    },
    stores: [],
    store: {},
    paginatorInfo: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      count: 0,
    },
    listStoreCity: [],
  }),
  mutations: {
    SET_STORES(state, data) {
      state.stores = data;
    },
    SET_STORE(state, data) {
      state.store = data;
    },
    SET_STORE_PAGINATOR(state, data) {
      state.paginatorInfo = {
        total: data.total,
        perPage: data.perPage,
        currentPage: data.currentPage,
        count: data.count,
      };
    },
    SET_LIST_STORE_CITY(state, data) {
      state.listStoreCity = data;
    },
    TOGGLE_QUERY_STATUS(state, status) {
      state.uiFlags.isFetching = status;
    },
    TOGGLE_MUTATE_STATUS(state, status) {
      state.uiFlags.isUpdating = status;
    },
  },
  actions: {
    async createStore({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation createStore($input: CreateStoreInput!) {
              createStore(input: $input) {
                id
                name
              }
            }
          `,
          variables: { input: payload },
        });
        commit("TOGGLE_QUERY_STATUS", false);
        return data;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
    async fetchStores({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query stores(
              $searchKeyword: String,
              $first: Int, $page: Int,
              $is_active: Boolean,
              $cities: [Uuid!],
              $orderBy: [QueryStoresOrderByOrderByClause!]
            ) {
              stores (
                searchKeyword: $searchKeyword,
                first: $first,
                page: $page,
                is_active: $is_active,
                cities: $cities,
                orderBy: $orderBy
              ) {
                paginatorInfo {
                  count
                  currentPage
                  perPage
                  total
                }
                data {
                  id
                  name
                  city_id
                  opening_time
                  closing_time
                  is_active
                }
              }
            }
          `,
          variables: {
            searchKeyword: payload.search,
            first: payload.first,
            page: payload.page,
            orderBy: payload.orderBy,
            is_active: payload.is_active,
            cities: payload.cities,
          },
          fetchPolicy: "network-only",
        });
        commit("SET_STORES", data.data.stores.data);
        commit("SET_STORE_PAGINATOR", data.data.stores.paginatorInfo);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.stores;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
    async fetchStore({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchStore($id: Uuid!) {
              store(id: $id) {
                id
                name
                contact_phone
                opening_time
                closing_time
                city_id
                latitude
                longitude
                address
                zipcode
                is_active
              }
              storeMembers(store_id: $id) {
                user_id
                user_name
              }
            }
          `,
          variables: { id: payload.id },
          fetchPolicy: "network-only",
        });
        commit("SET_STORE", data.data);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.store;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
    async updateStore({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation updateStore($id: Uuid!, $input: UpdateStoreInput!, $members: [Uuid!]!) {
              updateStore(id: $id, input: $input) {
                id
                name
              }
              saveStoreMembers(store_id: $id, members: $members) {
                user_id
                user_name
              }
            }
          `,
          variables: { id: payload.id, input: payload.input, members: payload.members },
        });
        commit("TOGGLE_QUERY_STATUS", false);
        return data;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
    async deleteStore({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation deleteStore($id: Uuid!) {
              deleteStore(id: $id){
                id
                name
              }
            }
          `,
          variables: { id: payload.id },
        });
        commit("SET_STORE", data.data.deleteStore);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.store;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
    async fetchListStoreCity({ commit }) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchListStoreCity{
              listStoreCity
            }
          `,
          fetchPolicy: "network-only",
        });
        commit("SET_LIST_STORE_CITY", data.data.listStoreCity);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.listStoreCity;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
  },
  getters: {
    getStores(state) {
      return state.stores;
    },
    getStore(state) {
      return state.store;
    },
    getUIFlags(state) {
      return state.uiFlags;
    },
    getPaginatorInfo(state) {
      return state.paginatorInfo;
    },
    getListStoreCity(state) {
      return state.listStoreCity;
    },
  },
};

export default ManageStoreStore;
