import { getUser } from "@/api/user";

const UserStore = {
  namespaced: true,
  state: () => ({
    userId: "",
    clientId: "",
    userFullName: "",
    role: "",
    permissions: [],
  }),
  mutations: {
    SET_USER_FULLNAME(state, data) {
      state.userFullName = data;
    },
    SET_USER_ID(state, data) {
      state.userId = data;
    },
    SET_CLIENT_ID(state, data) {
      state.clientId = data;
    },
    SET_USER_ROLE(state, data) {
      state.role = data;
    },
    SET_PERMISSIONS(state, { roles }) {
      const permissionList = [];
      roles[0].permissions.forEach((permission) => {
        permissionList.push(permission.name);
      });
      state.permissions = permissionList;
    },
  },
  actions: {
    async getUserData({ commit }) {
      try {
        const { data } = await getUser();
        commit("SET_USER_FULLNAME", data.user_f_name ? `${data.user_f_name} ${data.user_l_name}` : data.name);
        commit("SET_USER_ID", data.id);
        commit("SET_CLIENT_ID", data.client_id);
        commit("SET_USER_ROLE", data.roles[0].name);
        commit("SET_PERMISSIONS", data);
        localStorage.setItem("userData", JSON.stringify(data));
      } catch {
        throw Error();
      }
    },
    rememberMe(_event, value) {
      const userData = JSON.parse(String(localStorage.getItem("userData")));
      let rmmbrMe = JSON.parse(String(localStorage.getItem("rememberMe")));
      if (rmmbrMe) {
        // add user answer on list
        rmmbrMe[userData.id] = value;
      } else {
        rmmbrMe = {};
        // add user answer on list
        rmmbrMe[userData.id] = value;
      }
      localStorage.setItem("rememberMe", JSON.stringify(rmmbrMe));
    },
  },
  getters: {
    getUserFullName(state) {
      if (state.userFullName) return state.userFullName;
      const userData = JSON.parse(String(localStorage.getItem("userData")));
      const userFullName = userData.name;
      return userFullName;
    },
    getUserRole(state) {
      if (state.role) return state.role;
      const userData = JSON.parse(String(localStorage.getItem("userData")));
      const role = userData.roles[0].name;
      return role;
    },
    hasPermission: (state) => (item) => {
      let permissions = [];
      if (state.permissions.length > 0) {
        permissions = state.permissions;
      } else {
        const userData = JSON.parse(String(localStorage.getItem("userData")));
        const permissionList = [];
        userData.roles[0].permissions.forEach((permission) => {
          permissionList.push(permission.name);
        });
        permissions = permissionList;
      }
      const isHasPermission = permissions.includes(item);
      return isHasPermission;
    },
    getUserId(state) {
      if (state.userId) return state.userId;
      const userData = JSON.parse(String(localStorage.getItem("userData")));
      return userData.id;
    },
    getClientId(state) {
      if (state.clientId) return state.clientId;
      const userData = JSON.parse(String(localStorage.getItem("userData")));
      return userData.client_id;
    },
    getIsMeRemembered() {
      const userData = JSON.parse(String(localStorage.getItem("userData")));
      const rmmbrMe = JSON.parse(String(localStorage.getItem("rememberMe")));
      if (rmmbrMe) {
        // check if user already on list to prevent ask more than once
        return Object.prototype.hasOwnProperty.call(rmmbrMe, userData.id);
      }
      return false;
    },
  },
};

export default UserStore;
