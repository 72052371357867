import API from "@/api/api";
import { USER_API } from "@/api/endPoints";

const getAdmin = (payload) => API.get(`${USER_API.EXTERNAL.SHOW(payload.client_id, payload.id)}`);
const postUser = (payload) => API.post(`${USER_API.EXTERNAL.SHOW(payload.client_id, payload.id)}`, payload);
const patchUser = (payload) => API.patch(`${USER_API.EXTERNAL.PATCH(payload.client_id, payload.id)}`, payload);
const removeUser = (payload) => API.delete(`${USER_API.EXTERNAL.DELETE(payload.client_id, payload.id)}`, { data: payload.password });
const postResendEmail = (clientId, payload) => API.post(`${USER_API.EXTERNAL.RESEND_EMAIL(clientId)}`, payload);
const getExternalUserVerify = (token) => API.get(`${USER_API.EXTERNAL.VERIFY_USER(token)}`);
const patchPasswordExternal = (payload) => API.patch(`${USER_API.EXTERNAL.PATCH_PWD}`, payload);
const postForgetPassword = (payload) => API.post(`${USER_API.EXTERNAL.FORGET_PWD}`, payload);
const getForgetPasswordVerify = (token) => API.get(`${USER_API.EXTERNAL.VERIFY_PWD(token)}`);

export {
  getAdmin,
  postUser,
  patchUser,
  removeUser,
  postResendEmail,
  getExternalUserVerify,
  patchPasswordExternal,
  postForgetPassword,
  getForgetPasswordVerify,
};
