import gql from "graphql-tag";
import graphqlClient from "@/apollo/graphql";
import { getUserByClientIdAll } from "@/api/user";

const StoreMembersStore = {
  namespaced: true,
  state: () => ({
    uiFlags: {
      isFetching: false,
      isUpdating: false,
    },
    storeListMembers: [],
  }),
  mutations: {
    SET_LIST_MEMBERS(state, data) {
      state.storeListMembers = data;
    },
    TOGGLE_QUERY_STATUS(state, status) {
      state.uiFlags.isFetching = status;
    },
    TOGGLE_MUTATE_STATUS(state, status) {
      state.uiFlags.isUpdating = status;
    },
  },
  actions: {
    async fetchExternalUserByClientIdAll({ commit }, page) {
      try {
        const { data } = await getUserByClientIdAll(page);
        let members = {};
        if (data.data.data) {
          members = {
            admins: data.data.data.filter((item) => item.roles[0].name === "externalUsers-pharmacies-admin").map((item) => ({ id: item.id, name: item.name })),
            pharmacists: data.data.data.filter((item) => item.roles[0].name === "externalUsers-pharmacies-staff").map((item) => ({ id: item.id, name: item.name })),
          };
        } else {
          members = data;
        }
        commit("SET_LIST_MEMBERS", members);
        return members;
      } catch (error) {
        commit("SET_LIST_MEMBERS", []);
        throw error.response.data.message;
      }
    },
    async saveStoreMembers({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation saveStoreMembers($store_id: Uuid!, $members: [Uuid!]!) {
              saveStoreMembers(store_id: $store_id, members: $members) {
                store_id
                user_id
                user_name
              }
            }
          `,
          variables: { store_id: payload.store_id, members: payload.members },
        });
        commit("TOGGLE_QUERY_STATUS", false);
        return data;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
  },
  getters: {
    getStoreListMembers(state) {
      return state.storeListMembers;
    },
    getUIFlags(state) {
      return state.uiFlags;
    },
  },
};

export default StoreMembersStore;
