<template>
  <left-content-page v-if="isShowPage" :title="title" :subTitle="subTitle"/>
</template>

<script>
import Vue from "vue";
import LeftContentPage from "@/components/pages/LeftContentPage.vue";

export default Vue.extend({
  name: "VerificationSent",
  data() {
    return {
      title: "Petunjuk verifikasi akun telah dikirim",
      subTitle: "",
      isShowPage: false,
    };
  },
  components: {
    LeftContentPage,
  },
  mounted() {
    const userEmail = String(localStorage.getItem("email"));
    this.subTitle = `Silahkan periksa email ${userEmail} dan ikuti petunjuk yang diberikan untuk melanjutkan proses verifikasi akun Anda.`;
  },
  created() {
    this.isShowPage = true;
  },
});
</script>
