export default [
  {
    path: "/pharmacy/:store/:id",
    name: "Pharmacy",
    meta: {
      breadcrumb: [
        { title: "Beranda", url: "/" },
        { title: "Pesan Antar Farmasi", active: true },
      ],
      title: "Kimia Farma Gondangdia",
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "PharmacyDelivery" */ "../../views/pharmacyDelivery/Pharmacy.vue"),
  },
];
