<template>
  <a-layout-header
    style="background: #fff; padding: 16px 24px;
      border-top: 1px solid #F2F2F2; height: auto; line-height: 0px"
  >
    <a-row type="flex" justify="space-between">
      <a-col>
        <div style="display: flex">
          <font-awesome-icon
            icon="angle-left"
            class="p-header-icon-back"
            v-show="$route.name === 'Update Stock'"
            @click="$router.back()"
          />
          <h3 class="margin-baseT p-header-title">{{ headerTitle }}</h3>
        </div>
      </a-col>
      <a-col>
        <portal-target name="header-toolbar"></portal-target>
      </a-col>
    </a-row>
  </a-layout-header>
</template>

<script>
import Vue from "vue";
import { PortalTarget } from "portal-vue";

export default Vue.extend({
  components: {
    PortalTarget,
  },
  computed: {
    headerTitle() {
      if (this.$route.params.name) return `${this.$route.params.name}`;
      if (this.$route.query.name) return `${this.$route.query.name} - ${this.$route.meta.title}`;
      if (this.$route.params.store) return `${this.titleCase(this.$route.params.store)}`;
      return this.$route.meta.title;
    },
  },
  methods: {
    openForm() {
      (this.$refs.form).showModal();
    },
    titleCase(str) {
      const splitStr = str.toLowerCase().split("-");
      for (let i = 0; i < splitStr.length; i += 1) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },
  },
});
</script>

<style lang="scss" scoped>
.p-header-icon-back {
  margin-top: 12px;
  margin-right: 16px;
  color: #0146ab;
  cursor: pointer;
  font-size: 20px;
  font-weight: 900;
}

.p-header-title {
  font-size: 32px;
  color: #4c4f54;
  line-height: 1.43;
  margin: 0;
}
</style>
