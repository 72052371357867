import gql from "graphql-tag";
import graphqlClient from "@/apollo/graphql";

const OrderRecipeStore = {
  namespaced: true,
  state: () => ({
    uiFlags: {
      isFetching: false,
      isUpdating: false,
    },
    order: {},
  }),
  mutations: {
    SET_ORDER(state, data) {
      state.order = data;
    },
    TOGGLE_QUERY_STATUS(state, status) {
      state.uiFlags.isFetching = status;
    },
    TOGGLE_MUTATE_STATUS(state, status) {
      state.uiFlags.isUpdating = status;
    },
  },
  actions: {
    async fetchOrder({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query order($id: Uuid!) {
              order(id: $id){
                id
                line_items{
                  variant_id
                  quantity
                  price
                }
                prescription{
                  prescription_number
                  created_at
                  client_supply_logo
                  client_logo
                  patient_address
                  doctor{
                    doctor_f_name
                    doctor_l_name
                    specialities{
                      speciality_name
                    }
                    doctor_sip
                    doctor_phone
                    doctor_signature
                    hospital{
                      hospital_name
                      hospital_address
                    }
                  }
                  patient{
                    patient_f_name
                    patient_l_name
                    patient_dob
                    patient_height
                    patient_weight
                    patient_age
                    patient_phone
                    patient_email
                    patient_bmi_category
                    patient_card_number
                    patient_class
                    client_name
                    precondition{
                      hypertension
                      heart_problems
                      diabetes
                      kidney_failure
                      smoker
                      pregnant
                      alcohol
                      menopause
                      cancer
                    }
                  }
                  patient_age
                  patient_gender
                  patient_weight
                  allergy
                  differential_diagnosis
                  medicine_prescriptions{
                    medicine{
                      brand
                    }
                    frequency
                    frequency_dd
                    timing
                    duration
                    duration_unit
                    amount
                    amount_unit
                    substitution
                    notes
                  }
                }
              }
              variantStoreByOrderId(order_id: $id){
                quantity
                variant{
                  id
                  product_id
                  name
                  sku
                  attributes{
                    name
                    pivot{
                      value
                    }
                  }
                }
              }
            }
          `,
          variables: {
            id: payload.id,
          },
          fetchPolicy: "network-only",
        });
        commit("SET_ORDER", data.data);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async processOrDeclineOrder({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation processOrDeclineOrder($order_id: Uuid, $input: ProcessOrDeclineOrderInput!) {
              processOrDeclineOrder(order_id: $order_id, input: $input) {
                id
                patient_name
              }
            }
          `,
          variables: { order_id: payload.order_id, input: payload.input },
        });
        commit("TOGGLE_QUERY_STATUS", false);
        return data;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
  },
  getters: {
    getOrder(state) {
      return state.order;
    },
  },
};

export default OrderRecipeStore;
