import Vue from "vue";
import Vuex from "vuex";
import FormStore from "@/store/modules/form.store";
import LoginStore from "@/store/modules/login.store";
import UserStore from "@/store/modules/user.store";
import AdminStore from "@/store/modules/admin.store";
import UserExternalStore from "@/store/modules/user-external-store";
import RoleStore from "@/store/modules/role.store";
import CompanyStore from "@/store/modules/company.store";
import PharmacistStore from "@/store/modules/pharmacist.store";
import ProductStore from "@/store/modules/product.store";
import MedicineStore from "@/store/modules/medicine.store";
import ManageStoreStore from "@/store/modules/manage-store.store";
import CityStore from "@/store/modules/city.store";
import StoreMembersStore from "@/store/modules/store-members.store";
import MenuStoreStore from "@/store/modules/menu-store.store";
import ShippingMethodStore from "@/store/modules/shipping-method.store";
import OrderListStore from "@/store/modules/order-list.store";
import OrderRecipeStore from "@/store/modules/order-recipe.store";
import OrderProsessStore from "@/store/modules/order-prosess.store";
import OrderDeliveryStore from "@/store/modules/order-delivery.store";
import OrderFinishStore from "@/store/modules/order-finish.store";
import OrderRejectedStore from "@/store/modules/order-rejected.store";
import StockProductStore from "@/store/modules/stock-product.store";
import HistoryPrescriptionsStore from "@/store/modules/history-prescription.store";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    FormStore,
    LoginStore,
    UserStore,
    AdminStore,
    UserExternalStore,
    RoleStore,
    CompanyStore,
    PharmacistStore,
    ProductStore,
    MedicineStore,
    CityStore,
    ManageStoreStore,
    StoreMembersStore,
    MenuStoreStore,
    ShippingMethodStore,
    OrderListStore,
    OrderRecipeStore,
    OrderProsessStore,
    OrderDeliveryStore,
    OrderFinishStore,
    OrderRejectedStore,
    StockProductStore,
    HistoryPrescriptionsStore,
  },
});
