import gql from "graphql-tag";
import graphqlClient from "@/apollo/graphql";

const OrderListStore = {
  namespaced: true,
  state: () => ({
    uiFlags: {
      isFetching: false,
      isUpdating: false,
    },
    orders: [],
    paginatorInfo: {
      total: 0,
      limit: 10,
      offset: 0,
    },
    countOrders: {},
    selectedData: {
      id: null,
      patientId: null,
    },
  }),
  mutations: {
    SET_ORDERS(state, data) {
      state.orders = data;
    },
    SET_ORDERS_MORE(state, data) {
      state.orders = state.orders.concat(data);
    },
    SET_ORDER_PAGINATOR(state, data) {
      state.paginatorInfo = {
        total: data.total,
        limit: data.limit,
        offset: data.offset,
      };
    },
    SET_COUNT_ORDERS(state, data) {
      state.countOrders = data;
    },
    SET_SELECTED_ID(state, data) {
      state.selectedData = data;
    },
    TOGGLE_QUERY_STATUS(state, status) {
      state.uiFlags.isFetching = status;
    },
    TOGGLE_MUTATE_STATUS(state, status) {
      state.uiFlags.isUpdating = status;
    },
  },
  actions: {
    async fetchOrders({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query orders(
              $store_id: Uuid,
              $state: [OrderState!],
              $relative_date: RelativeDate,
              $limit: Int,
              $offset: Int,
            ) {
              orders(store_id: $store_id, state: $state, relative_date: $relative_date, limit: $limit, offset: $offset){
                paginatorInfo {
                  total
                }
                data {
                  id
                  patient_id
                  patient_name
                  prescription_number
                  bill_address{
                    address_location
                  }
                  payment_total
                  updated_at
                  state
                }
              }
            }
          `,
          variables: {
            store_id: payload.store_id,
            state: payload.state,
            relative_date: payload.relative_date,
            limit: payload.limit,
            offset: payload.offset,
          },
          fetchPolicy: "network-only",
        });
        if (payload.offset > 0) {
          commit("SET_ORDERS_MORE", data.data.orders.data);
        } else {
          commit("SET_ORDERS", data.data.orders.data);
        }
        commit("SET_ORDER_PAGINATOR", data.data.orders.paginatorInfo);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.orders;
      } catch (error) {
        commit("SET_ORDERS", []);
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async fetchOrdersCount({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query orders(
              $store_id: Uuid,
              $relative_date: RelativeDate,
              $limit: Int,
            ) {
              recipe: orders(relative_date: $relative_date, state: CUSTOMER_PAYMENT_SUCCESS, store_id: $store_id, limit: $limit){
                paginatorInfo{
                  total
                }
              }
              prosess: orders(relative_date: $relative_date, state: ORDER_IS_PROCESSED_BY_PHARMACY, store_id: $store_id, limit: $limit){
                paginatorInfo{
                  total
                }
              }
              delivery: orders(
                relative_date: $relative_date,
                state: [
                  ORDER_IS_WAITING_FOR_PICKED_UP,
                  RE_ATTEMPT_PICKUP,
                  COURIER_ARRIVED_AT_PICKUP_LOCATION,
                  ORDER_IS_PICKED_BY_COURIER,
                  ITEM_ON_THE_WAY_TO_CUSTOMER,
                  COURIER_ARRIVED_AT_DESTINATION
                ],
                store_id: $store_id,
                limit: $limit,
              ){
                paginatorInfo{
                  total
                }
              }
              finish: orders(relative_date: $relative_date, state: ITEM_DELIVERED, store_id: $store_id, limit: $limit){
                paginatorInfo{
                  total
                }
              }
              rejected: orders(
                relative_date: $relative_date,
                state: [ORDER_DECLINED_BY_PHARMACY, CUSTOMER_REFUND_PROCESSED],
                store_id: $store_id,
                limit: $limit,
              ){
                paginatorInfo{
                  total
                }
              }
            }
          `,
          variables: {
            store_id: payload.store_id,
            relative_date: payload.relative_date,
            limit: 11,
          },
          fetchPolicy: "network-only",
        });
        commit("SET_COUNT_ORDERS", data.data);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data;
      } catch (error) {
        commit("SET_COUNT_ORDERS", {});
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async updateSelectedList({ commit }, payload) {
      commit("SET_SELECTED_ID", payload);
    },
    async updateOrders({ getters, commit }, payload) {
      if (payload.id) {
        const data = await getters.getOrders.filter((obj) => obj.id !== payload.id);
        commit("SET_ORDERS", data);
        commit("SET_ORDER_PAGINATOR", {
          total: getters.getPaginatorInfo.total - 1,
          limit: getters.getPaginatorInfo.limit,
          offset: getters.getPaginatorInfo.offset,
        });
        commit("SET_SELECTED_ID", { id: null, patientId: null });
      } else {
        console.log("List Null");
      }
    },
    async emptyOrders({ commit }) {
      commit("SET_ORDERS", []);
      commit("SET_ORDER_PAGINATOR", {
        total: 0,
        limit: 10,
        offset: 0,
      });
    },
  },
  getters: {
    getOrders(state) {
      return state.orders;
    },
    getPaginatorInfo(state) {
      return state.paginatorInfo;
    },
    getCountOrders(state) {
      return state.countOrders;
    },
    getSelectedId(state) {
      return state.selectedData;
    },
  },
};

export default OrderListStore;
