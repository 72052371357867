// Company
export const COMPANY_API = {
  GET: "/v2/clients",
};

// User
export const USER_API = {
  LOGIN: "/v1/users/login",
  REFRESH: "/v1/users/refresh",
  INTERNAL: {
    ME: "/v1/users/me",
  },
  EXTERNAL: {
    GET: "/v1/users/all?external_only=1",
    GET_USERS: (clientId) => `v1/clients/${clientId}/users`,
    SHOW: (clientId, userId) => `v1/clients/${clientId}/users/${userId}`,
    LOGIN: "/v1/external/users/login",
    ME: "/v1/external/users/me",
    PATCH: (clientId, userId) => `/v1/clients/${clientId}/users/${userId}`,
    DELETE: (clientId, userId) => `/v1/clients/${clientId}/users/${userId}`,
    RESEND_EMAIL: (clientId) => `/v1/clients/${clientId}/users/resend/email`,
    VERIFY_USER: (token) => `/v1/external/users/${token}/verify`,
    PATCH_PWD: "/v1/external/users/password",
    FORGET_PWD: "/v1/external/users/forget-password",
    VERIFY_PWD: (token) => `/v1/external/users/forget-password/${token}/verify`,
  },
};

// Admin
export const ADMIN_API = {
  GET: "/v1/users",
  SHOW: (adminId) => `/v1/users/${adminId}`,
};

// Medicine
export const MEDICINE_API = {
  GET: "/v1/medicines",
  SHOW: (medicineId) => `/v1/medicines/${medicineId}`,
};

// City
export const CITY_API = {
  GET: "/v1/cities",
};
