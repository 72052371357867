import { getMedicines } from "@/api/medicine";

const MedicineStore = {
  namespaced: true,
  state: () => ({
    medicines: [],
  }),
  mutations: {
    SET_MEDICINES(state, data) {
      state.medicines = data;
    },
  },
  actions: {
    async fetchMedicines({ commit }, page) {
      try {
        const { data } = await getMedicines(page);
        commit("SET_MEDICINES", data.data.map((item) => {
          const tmp = item;
          // need add key and title for transfer antdv
          // as it stated required for data source
          tmp.key = item.id;
          tmp.title = item.brand;
          return tmp;
        }));
        return data;
      } catch (error) {
        throw error.response.data.message;
      }
    },
  },
  getters: {
    getStateMedicines(state) {
      return state.medicines;
    },
  },
};

export default MedicineStore;
