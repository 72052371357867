/* eslint-disable no-param-reassign */
import axios from "axios";
import { USER_API } from "@/api/endPoints";

const baseDomain = `${process.env.VUE_APP_API_HOST}`;
const baseURL = `${baseDomain}/api/`;

const API = axios.create({
  baseURL,
  withCredentials: false,
});

API.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = localStorage.getItem("accessToken");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

let isAlreadyFetchingAccessToken = false;
let isAlreadyReturnGlobalError = false;
let subscribers = [];

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter((callback) => callback(accessToken));
}

function addSubscriber(callback) {
  subscribers.push(callback); // add request queue
}

const refreshToken = () => API.post(
  USER_API.REFRESH,
  {},
  {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  },
);

// intercept if token expired,then extend
API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error || !error.response) {
      if (!isAlreadyReturnGlobalError) {
        isAlreadyReturnGlobalError = true;
        setTimeout(() => {
          isAlreadyReturnGlobalError = false;
        }, 5000);
      }
    }

    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (
      status === 401
      && originalRequest.method !== "delete"
      && !originalRequest.url.includes("v1/users/refresh")
    ) {
      // console.log("try reauth");
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;

        // console.log("reauth processing");
        refreshToken().then((response) => {
          isAlreadyFetchingAccessToken = false;

          const newTokenBearer = response.headers.authorization;
          const newAccessToken = newTokenBearer.split(" ")[1];
          localStorage.setItem("accessToken", newAccessToken);
          API.defaults.headers.common.Authorization = newTokenBearer;
          originalRequest.headers.Authorization = newTokenBearer;
          /*
            sent new token to parent
            so parent will not failed when do refresh token
          */
          window.parent.postMessage({ event: "newToken", newToken: newAccessToken }, "*");
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ event: "newToken", newToken: newAccessToken }),
            );
          }
          // console.log("reauth success");
          onAccessTokenFetched(newAccessToken);
        });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken) => {
          // console.log("try request again");
          localStorage.setItem("accessToken", accessToken);
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  },
);

export {
  API, refreshToken,
};
