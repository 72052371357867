var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"layout":"vertical","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Password","validate-status":_vm.checkError('password') ? 'error' : '',"help":_vm.checkError('password') || ''}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        {
          rules: [
            {
              required: true,
              message: 'Data tidak valid',
              pattern: _vm.passwordRegex,
            },
            {
              validator: _vm.validateToNextPassword,
            } ],
        } ]),expression:"[\n        'password',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Data tidak valid',\n              pattern: passwordRegex,\n            },\n            {\n              validator: validateToNextPassword,\n            },\n          ],\n        },\n      ]"}],attrs:{"size":"large","placeholder":"Tulis password","type":"password"}})],1),_c('a-form-item',{attrs:{"label":"Konfirmasi Password","validate-status":_vm.checkError('confirm') ? 'error' : '',"help":_vm.checkError('confirm') || ''}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'confirm',
        {
          rules: [
            {
              required: true,
              message: 'Data tidak valid',
            },
            {
              validator: _vm.compareToFirstPassword,
            } ],
        } ]),expression:"[\n        'confirm',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Data tidak valid',\n            },\n            {\n              validator: compareToFirstPassword,\n            },\n          ],\n        },\n      ]"}],attrs:{"size":"large","placeholder":"Tulis password"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',[_c('span',{staticClass:"color-disabled p-text-italic"},[_vm._v(" Pastikan password sama, berjumlah 8-15 karakter, mengandung huruf kapital, huruf kecil, angka, dan karakter spesial #?!@$%^&*-._ ")])]),_c('a-form-item',[_c('a-button',{staticClass:"margin-baseT",attrs:{"block":"","type":"primary","size":"large","shape":"round","html-type":"submit","disabled":_vm.hasErrors(_vm.form.getFieldsError()),"loading":_vm.loading}},[_vm._v(_vm._s(_vm.buttonName))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }