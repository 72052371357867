<template>
  <div class="login">
    <a-form
      layout="vertical"
      :form="form"
      @submit="handleSubmit"
      :hideRequiredMark="true"
    >
      <a-form-item
        label="Email"
        :validate-status="checkError('email') ? 'error' : ''"
        :help="checkError('email') || ''"
      >
        <a-input
          type="email"
          size="large"
          placeholder="nama@email.com"
          v-decorator="[
            'email',
            { rules: [{ required: true, message: 'Data tidak valid',
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }] },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="Password"
        :validate-status="checkError('password') ? 'error' : ''"
        :help="checkError('password') || ''"
      >
        <a-input-password
          size="large"
          placeholder="Tulis password"
          v-decorator="[
            'password',
            {
              rules: [{
                required: true,
                message: 'Data tidak valid',
                pattern: passwordRegex,
              }]
            },
          ]"
        />
      </a-form-item>
      <router-link to="/forgot-password" class="p-small-link color-secondary">
        Lupa password?
      </router-link>
      <a-form-item>
        <a-button
          block
          type="primary"
          size="large"
          shape="round"
          html-type="submit"
          :disabled="hasErrors(form.getFieldsError())"
          :loading="loading"
          class="margin-largeT login-button"
        >
          Masuk
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { message, Modal } from "ant-design-vue";
import { passwordRegex } from "@/helpers/utils";

export default Vue.extend({
  name: "LoginForm",
  data() {
    return {
      loginError: false,
      passwordRegex,
    };
  },
  computed: {
    ...mapGetters("FormStore", {
      hasErrors: "getErrors",
      form: "getForm",
      loading: "getLoading",
    }),
  },
  methods: {
    ...mapMutations("FormStore", {
      setForm: "SET_FORM",
      validateFirst: "VALIDATE_FIRST",
      setLoading: "SET_LOADING",
    }),
    ...mapActions("LoginStore", ["login", "loginExternal"]),
    ...mapActions("UserStore", ["getUserData", "rememberMe"]),
    ...mapGetters("UserStore", ["getIsMeRemembered"]),
    checkError(field) {
      const { getFieldError, isFieldTouched } = this.form;
      return (isFieldTouched(field) && getFieldError(field));
    },
    getUserDataAll() {
      this.getUserData().then(() => {
        // check if already asked about save password
        const isMeRemembered = this.getIsMeRemembered();
        if (!isMeRemembered) {
          Modal.confirm({
            title: "Apakah anda ingin otomatis masuk untuk selanjutnya?",
            okText: "Ya",
            cancelText: "Tidak",
            onOk: () => {
              this.rememberMe(true);
              this.$router.push({ name: "Home" });
            },
            onCancel: () => {
              this.rememberMe(false);
              this.$router.push({ name: "Home" });
            },
          });
          this.setLoading(false);
        } else {
          this.setLoading(false);
          this.$router.push({ path: "/home" });
        }
      }).catch(() => {
        message.error("Maaf, ada kesalahan dari kami. Mohon ulangi beberapa saat lagi.");
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        const userDataExternal = {
          email: values.email,
          password: values.password,
        };

        if (!err) {
          this.setLoading(true);
          this.loginExternal(userDataExternal).then(() => {
            this.getUserDataAll();
          }).catch(() => {
            this.setLoading(false);
            message.error("Login gagal, data tidak valid.");
          });
        }
      });
    },
  },
  created() {
    this.setForm(this.$form.createForm(this, { name: "Login_form" }));
  },
  mounted() {
    this.validateFirst(this);
  },
});
</script>

<style lang="scss" scoped>
  .login-button {
    border-radius: 24px;
  }

  .radio-role.ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
    height: 0;
    background-color: transparent;
  }

  // .role-content {
  //   padding: 15px 24px;
  // }

  // .radio-role-group.ant-radio-group-large
  // .radio-role.ant-radio-button-wrapper > span.ant-radio-button {
  //   padding: 15px 24px;
  //   background: red;
  // }

  // label.radio-role.ant-radio-button-wrapper span.ant-radio-button {
  //   padding: 15px 24px;
  // }
</style>
