import API from "./api";
import { COMPANY_API } from "./endPoints";

const getCompanies = ({
  limit, offset, search, sorter,
}) => API.get(`${COMPANY_API.GET}?limit=${limit}&offset=${offset}${search || ""}${sorter || ""}`);
const getCompany = (id) => API.get(`${COMPANY_API.SHOW(id)}`);

export {
  getCompanies,
  getCompany,
};
