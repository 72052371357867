import gql from "graphql-tag";
import graphqlClient from "@/apollo/graphql";

const MenuStoreStore = {
  namespaced: true,
  state: () => ({
    uiFlags: {
      isFetching: false,
      isUpdating: false,
    },
    stores: [],
    paginatorInfo: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      count: 0,
    },
    shippingMethods: [],
  }),
  mutations: {
    SET_STORES_MENU(state, data) {
      state.stores = data;
    },
    SET_STORE(state, data) {
      state.store = data;
    },
    SET_STORE_PAGINATOR(state, data) {
      state.paginatorInfo = {
        total: data.total,
        perPage: data.perPage,
        currentPage: data.currentPage,
        count: data.count,
      };
    },
    SET_SHIPPING_METHODS(state, data) {
      state.shippingMethods = data;
    },
    TOGGLE_QUERY_STATUS(state, status) {
      state.uiFlags.isFetching = status;
    },
    TOGGLE_MUTATE_STATUS(state, status) {
      state.uiFlags.isUpdating = status;
    },
  },
  actions: {
    async fetchStores({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query stores(
              $first: Int, $page: Int,
              $is_active: Boolean,
              $is_member: Boolean,
              $orderBy: [QueryStoresOrderByOrderByClause!]
            ) {
              stores (
                first: $first,
                page: $page,
                is_active: $is_active,
                is_member: $is_member,
                orderBy: $orderBy
              ) {
                paginatorInfo {
                  count
                  currentPage
                  perPage
                  total
                }
                data {
                  id
                  name
                  is_active
                }
              }
              shippingMethods(first: $first, page: $page){
                data{
                  id
                  name
                }
              }
            }
          `,
          variables: {
            first: payload.first,
            page: payload.page,
            orderBy: payload.orderBy,
            is_active: payload.is_active,
            is_member: true,
          },
          fetchPolicy: "network-only",
        });
        commit("SET_STORES_MENU", data.data.stores.data);
        commit("SET_STORE_PAGINATOR", data.data.stores.paginatorInfo);
        commit("SET_SHIPPING_METHODS", data.data.shippingMethods.data);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.stores;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error.response.data;
      }
    },
  },
  getters: {
    getStoresMenu(state) {
      return state.stores;
    },
    getShippingMethods(state) {
      return state.shippingMethods;
    },
  },
};

export default MenuStoreStore;
