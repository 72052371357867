<template>
<div class="login">
<a-form
    layout="vertical"
    class="margin-largeT"
    :form="form"
    @submit="handleSubmit"
    :hideRequiredMark="true"
  >
    <a-form-item
      label="Email"
      :validate-status="checkError('email') ? 'error' : ''"
      :help="checkError('email') || ''"
    >
      <a-input
        type="email"
        size="large"
        placeholder="Enter Your Email"
        @change="errorForgot=false"
        v-decorator="[
        'email',
        { rules: [{ required: true, message: 'Data tidak valid',
        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }] },
      ]"
      />
    </a-form-item>
    <a-form-item>
      <a-button
        block
        type="primary"
        size="large"
        shape="round"
        html-type="submit"
        class="margin-baseT"
        :disabled="hasErrors(form.getFieldsError())"
        :loading="loading"
      >Kirim</a-button>
    </a-form-item>
  </a-form>
</div>
</template>

<script>
import Vue from "vue";
import { Form, Input, Button } from "ant-design-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";

export default Vue.extend({
  name: "ForgotPasswordForm",
  computed: {
    ...mapGetters("FormStore", {
      hasErrors: "getErrors",
      form: "getForm",
      loading: "getLoading",
    }),
  },
  components: {
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-button": Button,
  },
  data() {
    return {
      errorForgot: false,
    };
  },
  methods: {
    ...mapMutations("FormStore", {
      setForm: "SET_FORM",
      validateFirst: "VALIDATE_FIRST",
      setLoading: "SET_LOADING",
    }),
    ...mapActions("UserExternalStore", {
      sendForgetPasswordExternal: "sendForgetPassword",
    }),
    checkError(field) {
      const { getFieldError, isFieldTouched } = this.form;
      return (
        (isFieldTouched(field) && getFieldError(field)) || this.errorForgot
      );
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(
        (err, values) => {
          if (!err) {
            this.setLoading(true);
            const payload = {
              ...values,
              source: "pharmacy_dashboard",
            };
            this.sendForgetPasswordExternal(payload).then((response) => {
              this.setLoading(false);
              if (response.status === "failed") {
                this.errorForgot = response.message;
              } else {
                localStorage.setItem("email", values.email);
                this.$router.push({ name: "PasswordRecoverySent" });
              }
            });
          }
        },
      );
    },
  },
  created() {
    this.setForm(this.$form.createForm(this, { name: "forgot_password_form" }));
  },
  mounted() {
    this.validateFirst(this);
  },
});
</script>
