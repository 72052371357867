<template>
  <a-layout-sider
    class="prixa-right-sidebar"
    style="flex: 0 0 350px; max-width: 350px; min-width: 380px; width: 380px;"
  >
    <portal-target class="portal-section" name="right-sidebar-content"></portal-target>
  </a-layout-sider>
</template>

<script>
import Vue from "vue";
import { PortalTarget } from "portal-vue";

export default Vue.extend({
  components: {
    PortalTarget,
  },
  computed: {
    headerTitle() {
      if (this.$route.query.name) return `${this.$route.query.name} - ${this.$route.meta.title}`;
      return this.$route.meta.title;
    },
  },
});
</script>

<style lang="scss" scoped>
  .p-sidebar-logo {
    flex-direction: row;
    align-items: center;
    margin: 24px 24px 40px;
  }
  .portal-section {
    height: inherit;
  }
  .prixa-right-sidebar {
    background: #fff;
    border-left: 1px solid rgba(204, 218, 238, 0.89);
    height: 100vh;
  }
</style>
