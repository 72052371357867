import gql from "graphql-tag";
import graphqlClient from "@/apollo/graphql";

const StockProductStore = {
  namespaced: true,
  state: () => ({
    uiFlags: {
      isFetching: false,
      isUpdating: false,
    },
    variants: [],
    paginatorInfo: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      count: 0,
    },
    memberStores: [],
    store: {},
    dailyInventories: [],
    updatedStock: {},
    variant: {},
  }),
  mutations: {
    SET_VARIANTS(state, data) {
      state.variants = data;
    },
    SET_PAGINATOR(state, data) {
      state.variantsPaginatorInfo = {
        total: data.total,
        perPage: data.perPage,
        currentPage: data.currentPage,
        count: data.count,
      };
    },
    SET_MEMBER_STORES(state, data) {
      state.memberStores = data;
    },
    SET_STORE(state, data) {
      state.store = data;
    },
    SET_VARIANT(state, data) {
      state.variant = data;
    },
    SET_DAILY_INVENTORIES(state, data) {
      state.dailyInventories = data;
    },
    SET_UPDATED_STOCK(state, data) {
      state.updatedStock = data;
    },
    TOGGLE_QUERY_STATUS(state, status) {
      state.uiFlags.isFetching = status;
    },
    TOGGLE_MUTATE_STATUS(state, status) {
      state.uiFlags.isUpdating = status;
    },
  },
  actions: {
    async updatedDailyInventoryStock({ commit }, payload) {
      commit("SET_UPDATED_STOCK", payload);
    },
    async subscribeDailyInventory(_vuex, payload) {
      try {
        const data = await graphqlClient.defaultClient.subscribe({
          query: gql`
            subscription subscriptionDailyInventories($store_id: Uuid!, $variant_id: Uuid!) {
              dailyInventory(store_id: $store_id, variant_id: $variant_id) {
                id
                date
                variant_id
                store_id
                beginning
                sold
                lost
                adjustment_positive
                adjustment_negative
                ending
              }
            }
          `,
          variables: {
            ...payload,
          },
        });
        return data;
      } catch (error) {
        return error;
      }
    },
    async fetchOneStore({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchOneStore($id: Uuid!) {
              store(id: $id) {
                id
                name
                address
                contact_phone
                opening_time
                closing_time
                is_active
              }
            }
          `,
          variables: {
            id: options.id,
          },
          fetchPolicy: "no-cache",
        });
        commit("SET_STORE", data.data.store);
        // commit("SET_PAGINATOR", data.data.stockItems.paginatorInfo);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.store;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async fetchDailyInventories({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchDailyInventories($id: Uuid!, $variantId: Uuid!, $date: DateRange!) {
              dailyInventories(store_id: $id, variant_id: $variantId, date: $date) {
                id
                date
                beginning
                sold
                lost
                adjustment_positive
                adjustment_negative
                ending
              }
            }
          `,
          variables: {
            id: options.id,
            variantId: options.variantId,
            date: options.date,
          },
          fetchPolicy: "no-cache",
        });
        commit("SET_DAILY_INVENTORIES", data.data.dailyInventories);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async fetchStoreUnmappingVariant({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchStoreUnmappingVariant(
              $id: Uuid!
              $store_id: Uuid!
              $orderBy: [QueryVariantsOrderByClause!]
              $first: Int = 10
              $page: Int = 1
            ) {
              store(id: $id) {
                id
                name
                address
                contact_phone
                opening_time
                closing_time
                is_active
              }
              unmappingVariantsInStore(
                store_id: $store_id
                orderBy: $orderBy
                first: $first
                page: $page
              ) {
                paginatorInfo {
                  count
                  currentPage
                  perPage
                  total
                }
                data {
                  id
                  product_id
                  name
                  discontinue_on
                }
              }
            }
          `,
          variables: {
            id: options.storeId,
            store_id: options.storeId,
            orderBy: options.orderBy,
            first: options.first,
            page: options.page,
          },
          fetchPolicy: "no-cache",
        });
        commit("SET_STORE", data.data.store);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.unmappingVariantsInStore;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async fetchUnmappingVariantsInStore({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchUnmappingVariantsInStore(
              $store_id: Uuid!
              $orderBy: [QueryVariantsOrderByClause!]
              $first: Int = 10
              $page: Int = 1
            ) {
              unmappingVariantsInStore(
                store_id: $store_id
                orderBy: $orderBy
                first: $first
                page: $page
              ) {
                paginatorInfo {
                  count
                  currentPage
                  perPage
                  total
                }
                data {
                  id
                  product_id
                  name
                  discontinue_on
                }
              }
            }
          `,
          variables: {
            store_id: options.storeId,
            orderBy: options.orderBy,
            first: options.first,
            page: options.page,
          },
          fetchPolicy: "no-cache",
        });
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.unmappingVariantsInStore;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async fetchStores({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchStores(
              $orderBy: [QueryStoresOrderByOrderByClause!]
              $is_active: Boolean = true
              $is_member: Boolean = true
              $first: Int = 10
              $page: Int
            ) {
              stores(
                orderBy: $orderBy
                is_active: $is_active
                is_member: $is_member
                first: $first
                page: $page
              ) {
                paginatorInfo {
                  count
                  currentPage
                  perPage
                  total
                  firstItem
                  lastItem
                  lastPage
                  hasMorePages
                }
                data {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            orderBy: options.orderBy,
            is_active: options.is_active,
            is_member: options.is_member,
            first: options.first,
            page: options.page,
          },
          fetchPolicy: "no-cache",
        });
        commit("SET_MEMBER_STORES", data.data.stores.data);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.stores;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async fetchStoresVariants({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchStoresVariants(
              $orderBy: [QueryStoresOrderByOrderByClause!]
              $is_active: Boolean = true
              $is_member: Boolean = true
              $first: Int = 10
              $page: Int
              $variantStoreId: Uuid!
              $variantOrderBy: [QueryVariantStoreOrderByClause]
              $variantSearchKeyword: String
              $variantFirst: Int = 10
              $variantPage: Int = 1
            ) {
              stores(
                orderBy: $orderBy
                is_active: $is_active
                is_member: $is_member
                first: $first
                page: $page
              ) {
                data {
                  id
                  name
                }
              }
              variantsStore(
                store_id: $variantStoreId
                orderBy: $variantOrderBy
                searchKeyword: $variantSearchKeyword
                first: $variantFirst
                page: $variantPage
              ) {
                paginatorInfo {
                  count
                  currentPage
                  perPage
                  total
                }
                data {
                  id
                  store_id
                  variant_id
                  price
                  quantity
                  is_active
                  updated_at
                  variant {
                    name
                    sku
                  }
                }
              }
            }
          `,
          variables: {
            orderBy: options.orderBy,
            is_active: options.is_active,
            is_member: options.is_member,
            first: options.first,
            page: options.page,
            variantStoreId: options.variantStoreId,
            variantOrderBy: options.variantOrderBy,
            variantSearchKeyword: options.variantSearchKeyword,
            variantFirst: options.variantFirst,
            variantPage: options.variantPage,
          },
          fetchPolicy: "no-cache",
        });
        commit("SET_MEMBER_STORES", data.data.stores.data);
        commit("SET_VARIANTS", data.data.variantsStore.data);
        commit("SET_PAGINATOR", data.data.variantsStore.paginatorInfo);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.variantsStore;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async fetchVariantsStore({ commit }, options) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query fetchVariantsStore(
              $store_id: Uuid!
              $orderBy: [QueryVariantStoreOrderByClause]
              $searchKeyword: String
              $first: Int = 10
              $page: Int = 1
            ) {
              variantsStore(
                store_id: $store_id
                orderBy: $orderBy
                searchKeyword: $searchKeyword
                first: $first
                page: $page
              ) {
                paginatorInfo {
                  count
                  currentPage
                  perPage
                  total
                }
                data {
                  id
                  store_id
                  variant_id
                  price
                  quantity
                  is_active
                  updated_at
                  variant {
                    name
                    sku
                  }
                }
              }
            }
          `,
          variables: {
            store_id: options.store_id,
            orderBy: options.orderBy,
            searchKeyword: options.searchKeyword,
            first: options.first,
            page: options.page,
          },
          fetchPolicy: "no-cache",
        });
        commit("SET_VARIANTS", data.data.variantsStore.data);
        commit("SET_PAGINATOR", data.data.variantsStore.paginatorInfo);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.variantsStore;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async createVariantStores({ commit }, payload) {
      commit("TOGGLE_MUTATE_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation createVariantStores($store_id: Uuid!, $variants: [AddVariantToStoreInput!]!) {
              createVariantStores(store_id: $store_id, variants: $variants) {
                variant_stores {
                  id
                  variant_id
                  store_id
                  price
                  quantity
                  is_active
                  variant {
                    product_id
                    name
                    sku
                  }
                }
              }
            }
          `,
          variables: {
            store_id: payload.storeId,
            variants: payload.variants,
          },
        });
        commit("TOGGLE_MUTATE_STATUS", false);
        return data.data;
      } catch (error) {
        commit("TOGGLE_MUTATE_STATUS", false);
        throw error;
      }
    },
  },
  getters: {
    getVariants(state) {
      return state.variants;
    },
    getUIFlags(state) {
      return state.uiFlags;
    },
    getMemberStores(state) {
      return state.memberStores;
    },
    getStore(state) {
      return state.store;
    },
    getVariant(state) {
      return state.variant;
    },
    getDailyInventories(state) {
      return state.dailyInventories;
    },
    getUpdatedStock(state) {
      return state.updatedStock;
    },
  },
};

export default StockProductStore;
