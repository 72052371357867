<template>
  <a-form
    layout="vertical"
    :form="form"
    @submit="handleSubmit"
    :hideRequiredMark="true"
  >
    <a-form-item
      label="Password"
      :validate-status="checkError('password') ? 'error' : ''"
      :help="checkError('password') || ''"
    >
      <a-input-password
        size="large"
        placeholder="Tulis password"
        v-decorator="[
          'password',
          {
            rules: [
              {
                required: true,
                message: 'Data tidak valid',
                pattern: passwordRegex,
              },
              {
                validator: validateToNextPassword,
              },
            ],
          },
        ]"
        type="password"
      />
    </a-form-item>
    <a-form-item
      label="Konfirmasi Password"
      :validate-status="checkError('confirm') ? 'error' : ''"
      :help="checkError('confirm') || ''"
    >
      <a-input-password
        size="large"
        placeholder="Tulis password"
        v-decorator="[
          'confirm',
          {
            rules: [
              {
                required: true,
                message: 'Data tidak valid',
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          },
        ]"
        @blur="handleConfirmBlur"
      />
    </a-form-item>
    <a-form-item>
      <span class="color-disabled p-text-italic">
        Pastikan password sama, berjumlah 8-15 karakter, mengandung huruf kapital,
        huruf kecil, angka, dan karakter spesial #?!@$%^&*-._
      </span>
    </a-form-item>
    <a-form-item>
      <a-button
        block
        type="primary"
        size="large"
        shape="round"
        html-type="submit"
        class="margin-baseT"
        :disabled="hasErrors(form.getFieldsError())"
        :loading="loading"
        >{{ buttonName }}</a-button
      >
    </a-form-item>
  </a-form>
</template>

<script>
import Vue from "vue";
import { Form, Input, Button } from "ant-design-vue";
import { mapMutations, mapGetters } from "vuex";
import { passwordRegex } from "@/helpers/utils";

export default Vue.extend({
  name: "PasswordForm",
  data() {
    return {
      passwordRegex,
    };
  },
  props: {
    buttonName: {
      type: String,
    },
    email: {
      type: String,
    },
    token: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("FormStore", {
      hasErrors: "getErrors",
      form: "getForm",
      loading: "getLoading",
      confirmDirty: "getConfirmDirty",
    }),
  },
  components: {
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input-password": Input.Password,
    "a-button": Button,
  },
  methods: {
    ...mapMutations("FormStore", {
      setForm: "SET_FORM",
      validateFirst: "VALIDATE_FIRST",
      setLoading: "SET_LOADING",
      handleConfirmBlur: "HANDLE_CONFIRM_BLUR",
    }),
    checkError(field) {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched(field) && getFieldError(field);
    },
    compareToFirstPassword(rule, value, callback) {
      const { getFieldValue } = this.form;

      if (value && value !== getFieldValue("password")) {
        callback("Data tidak valid");
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const { validateFields } = this.form;
      if (value && this.confirmDirty) {
        validateFields(["confirm"], { force: true });
      }
      callback();
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(
        (err, values) => {
          if (!err) {
            this.setLoading(true);
            const updatePasswordData = {
              token: this.token,
              password: values.password,
              password_confirmation: values.confirm,
            };
            const data = {
              updatePasswordData,
              setLoading: this.setLoading,
            };
            this.$emit("submitPassword", data);
          }
        },
      );
    },
  },
  created() {
    this.setForm(this.$form.createForm(this, { name: "new_password_form" }));
  },
  mounted() {
    this.validateFirst(this);
  },
});
</script>
