import gql from "graphql-tag";
import graphqlClient from "@/apollo/graphql";

const OrderProsessStore = {
  namespaced: true,
  state: () => ({
    uiFlags: {
      isFetching: false,
      isUpdating: false,
    },
    order: {},
  }),
  mutations: {
    SET_ORDER(state, data) {
      state.order = data;
    },
    TOGGLE_QUERY_STATUS(state, status) {
      state.uiFlags.isFetching = status;
    },
    TOGGLE_MUTATE_STATUS(state, status) {
      state.uiFlags.isUpdating = status;
    },
  },
  actions: {
    async fetchOrder({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.query({
          query: gql`
            query order($id: Uuid!) {
              order(id: $id){
                id
                store{
                  name
                  address
                  contact_phone
                }
                patient_name
                bill_address {
                  address_location
                  address_detail
                  phone
                }
                prescription{
                  prescription_number
                  client_supply_logo
                  client_logo
                }
                item_total
                discounts{
                  percentage
                  value_in_idr
                }
                total
                delivery_total
                admin_fee
                payment_total
                payments{
                  number
                  amount
                  payment_method_id
                  payment_method{
                    type
                    name
                  }
                  state
                  created_at
                }
                line_items{
                  variant_id
                  variant{
                    name
                    attributes{
                      name
                      pivot{
                        value
                      }
                    }
                  }
                  quantity
                  price
                }
              }
              variantStoreByOrderId(order_id: $id){
                variant{
                  id
                  product_id
                  name
                  sku
                  attributes{
                    name
                    pivot{
                      value
                    }
                  }
                  medicine_reference_id
                  medicine_reference_name
                }
              }
            }
          `,
          variables: {
            id: payload.id,
          },
          fetchPolicy: "network-only",
        });
        commit("SET_ORDER", data.data);
        commit("TOGGLE_QUERY_STATUS", false);
        return data.data.order;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
    async createPaxelDelivery({ commit }, payload) {
      commit("TOGGLE_QUERY_STATUS", true);
      try {
        const data = await graphqlClient.defaultClient.mutate({
          mutation: gql`
            mutation createPaxelDelivery($order_id: Uuid!) {
              createPaxelDelivery(order_id: $order_id) {
                id
                order_id
              }
            }
          `,
          variables: { order_id: payload.order_id },
        });
        commit("TOGGLE_QUERY_STATUS", false);
        return data;
      } catch (error) {
        commit("TOGGLE_QUERY_STATUS", false);
        throw error;
      }
    },
  },
  getters: {
    getOrder(state) {
      return state.order;
    },
  },
};

export default OrderProsessStore;
