<template>
  <div>
    <left-content-page v-if="isShowPage" :title="title" :subTitle="subTitle">
      <a-button type="primary" size="large" shape="round" @click="goToLogin"
    class="margin-baseT">
      {{ buttonName }}
    </a-button>
    </left-content-page>
  </div>
</template>

<script>
import Vue from "vue";
import { Button } from "ant-design-vue";
import LeftContentPage from "@/components/pages/LeftContentPage.vue";

export default Vue.extend({
  name: "PasswordChanged",
  data() {
    return {
      title: "Password akun Anda telah berhasil diganti",
      subTitle: "Silahkan masuk ke akun Anda dengan email dan password baru.",
      buttonName: "Masuk",
      isShowPage: false,
    };
  },
  components: {
    "a-button": Button,
    LeftContentPage,
  },
  methods: {
    goToLogin() {
      this.$router.push({ path: "/" });
    },
  },
  created() {
    this.isShowPage = true;
    if (this.$route.params.status !== true) {
      this.title = "Password akun Anda belum berhasil diubah.";
      this.subTitle = "Silakan ulangi kembali untuk mengganti password anda yang baru.";
      this.buttonName = "Coba Lagi";
    }
  },
});
</script>
