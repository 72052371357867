<template>
  <div>
    <left-content-page v-if="isShowPage" :smallTitle="smallTitle" :title="title">
      <password-form
      :email="email"
      :buttonName="buttonName"
      @submitPassword="submitPassword"
      :token="token"/>
    </left-content-page>
    <div class="loading-animation" v-else>
      <hollow-dots-spinner
        :animation-duration="1000"
        :dot-size="15"
        :dots-num="3"
        color="#0046AB"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import LeftContentPage from "@/components/pages/LeftContentPage.vue";
import PasswordForm from "@/components/forms/PasswordForm.vue";
import { mapActions } from "vuex";
import { HollowDotsSpinner } from "epic-spinners";

export default Vue.extend({
  name: "SignUpPasword",
  data() {
    return {
      smallTitle: "SELAMAT BERGABUNG!",
      title: "Masukkan password untuk mendaftarkan akun Anda:",
      buttonName: "Daftar",
      isShowPage: false,
      email: "",
      token: "",
    };
  },
  components: {
    LeftContentPage,
    PasswordForm,
    HollowDotsSpinner,
  },
  methods: {
    ...mapActions("UserExternalStore", {
      verifyExternalUser: "verifyUser",
      updateExternalUserPassword: "updatePassword",
    }),
    submitPassword(data) {
      const { updatePasswordData, setLoading } = data;
      this.updateExternalUserPassword(updatePasswordData).then(() => {
        setLoading(false);
        this.$router.push({ name: "PasswordChanged", params: { status: true } });
      }).catch(() => {
        setLoading(false);
        this.$router.push({ name: "PasswordChanged", params: { status: false } });
      });
    },
  },
  created() {
    const { token } = this.$route.params;
    this.token = token;
    this.verifyExternalUser(this.$route.params.token).then((response) => {
      this.email = response.data.email;
      this.isShowPage = true;
    }).catch(() => {
      window.location.href = "/login";
    });
  },
});
</script>

<style lang="scss" scoped>
.loading-animation {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
